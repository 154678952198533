import { Table, Typography, Tabs } from 'antd';
import React from 'react';
import AgentList from './ components/AgentList';
import AgentActivityTable from './ components/AgentActivityTable';
import { canViewAgentActivity, canViewAgentList } from '../../configs/constants';
import useLocalStorage from '../../configs/localStorage';


function SupportTeamMetrics() {
    const [auth, setAuth] = useLocalStorage('auth', null);
  const permissions = auth?.user.role.permissions.map((per) => per.name);
  const tabItems = [
    permissions.includes(canViewAgentList) && {
      key: '1',
      label: 'Agent List',
      children: <AgentList />,
    },

    permissions.includes(canViewAgentActivity) && {
      key: '2',
      label: 'Agent Activity',
      children: <AgentActivityTable />,
    },
  ];

  return (
    <div
      style={{
        padding: 24,
        minHeight: 360,
      }}
    >
      <Typography.Title
        level={2}
        style={{
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        Support Team Metrics
      </Typography.Title>
      <Tabs items={tabItems} />
    </div>
  );
}

export default SupportTeamMetrics;

import { useEffect, useReducer, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  message,
  Switch,
  List,
  Drawer,
  Divider,
  Select,
} from 'antd';
import classes from './style/task-board.module.css';
import {
  useAxiosGetTaskManagement,
  useAxiosPostTaskManagement,
  useAxiosTaskManagementGetV2,
  useAxiosTaskManagementPut,
} from '../../configs/axios';
import ChangeOrderStatusModalComponent from '../home/ChangeOrderStatusModalComponent';

import OrderMapModalComponent from '../home/OrderMapModalComponent';
import OrderCartModalComponent from '../home/OrderCartModalComponent';
import OrderRefundModal from '../home/OrderRefundModal';
import EditOrderModal from '../home/EditOrderModal';
import AssignCourierModal from '../OrderWithMap/AssignCourierModal';
import ToInProgress from './icons/ToInProgress';
import DrawerChat from '../Chatv2/DrawerChat';
import MerchantInformationModal from '../home/MerchantInformationModal';
import VirtualList from 'rc-virtual-list';
import RenderGroupedTasks from './RenderGroupedTasks';
import SubTaskModal from './SubTaskModal';
import RenderTasks from './RenderTasks';
import TaskDrawer from './TaskDrawer';
import Search from 'antd/es/transfer/search';
import RemoteSelect from '../utility/RemoteSelect';
import CustomerInformationModal from './CustomerInformationModal';
import dayjs from 'dayjs';
import EditProductIssueModal from './EditProductIssueModal';
import DriverInformationModal from './DriverInformationModal';
import RenderTaskWithChat from './RenderTaskWithChat';
import CompletedTickets from './CompletedTicketsModal';

var updateLocale = require('dayjs/plugin/updateLocale');

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1m',
    m: '1m',
    mm: '%dMs',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mon',
    MM: '%dmon',
    y: '1y',
    yy: '%dy',
  },
});

const TicketOnChat = ({dataTasks, getTaskLoading, getTasks}) => {
  const initValue = {
    taskIssueId: 0,
    selectedOrder: null,
    openChangeOrderStatusModal: false,
    openOrderMap: false,
    openOrderCart: false,
    openRefund: false,
    openOrderEdit: false,
    openDrawerChat: false,
    openMerchantModel: false,
    selectedSubTasks: [],
    openSubTaskModal: false,
    openEditProductIssueModal: false,
    statusTexts: {},
    isGrouped: false,
    openTaskDrawer: false,
    selectedTask: null,
    merchantTrobleIds: null,
    search: null,
    openFilterDrawer: false,
    cities: null,
    merchantIds: null,
    selectedProduct: null,
    openCustomerInfo: false,
    customer_id: undefined,
    language: localStorage.getItem('language') || 'en',
    isModalVisible: false,

  };
  const [states, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );

  
 
  
  const { request: sendCommentRequest, loading: sendCommentLoading } =
    useAxiosPostTaskManagement('/myTasksLezzooBoard/comment', {
      autoRun: false,
    });
  const sendCommend = async (body) => {
    await sendCommentRequest(body);
    message.success('Comment sent successfully');
    getTasks();
  };
  const { loading: changeTaskLoading, request: changeTaskRequest } =
    useAxiosTaskManagementPut('/OperationTasks/', {
      autoRun: true,
    });
  const timer = useRef(null);

  useEffect(() => {
    localStorage.setItem('language', states.language);

    if (timer.current) {
      clearInterval(timer.current);
    }
    try {
      timer.current = setInterval(() => {
        getTasks(states.taskIssueId);
      }, 15000); // 15 seconds
    } catch (err) {
      message.error(err.message);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, []);
  const searchTimer = useRef(null);

  const handleSearch = (e, filedName = 'search') => {
    clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setState({ [filedName]: e.target.value.toLowerCase() });
      getTasks({
        [filedName]: e.target.value.toLowerCase(),
      });
    }, 500);
  };
  const changeTaskStatus = async ({
    operation_task_id,
    task_status,
    task_comment,
  }) => {
    try {
      let newStatus = '';
      switch (task_status) {
        case 'todo':
          newStatus = 'in_progress';
          break;
        case 'in_progress':
          newStatus = 'done';
          break;
        case 'done':
          newStatus = 'todo';
          break;
        default:
          newStatus = 'todo';
      }
      await changeTaskRequest({
        operation_task_id,
        task_status: newStatus,
        task_comment,
      });
      message.success('Task status updated successfully');
      getTasks();
    } catch (error) {
      message.error('Failed to update task status');
    }
  };

  const onCloseChangeOrderStatusModal = () => {
    setState({ openChangeOrderStatusModal: false, selectedOrder: null });
  };
  const onOpenChangeOrderStatusModal = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openChangeOrderStatusModal: true, selectedOrder: order });
  };

  const onCloseMerchantInfo = () => {
    setState({ openMerchantModel: false, selectedOrder: null });
  };

  const onOpenMerchantInfo = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openMerchantModel: true, selectedOrder: order });
  };
  const onOpenEditProductIssueModal = (product) => {
    setState({ openEditProductIssueModal: true, selectedProduct: product });
  };

  const onOpenOrderMap = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openOrderMap: true, selectedOrder: order });
  };

  const onCloseOrderMap = (order) => {
    setState({ openOrderMap: false, selectedOrder: null });
  };
  const onOpenOrderCart = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openOrderCart: true, selectedOrder: order });
  };

  const onCloseOrderCart = (order) => {
    setState({ openOrderCart: false, selectedOrder: null });
  };
  const onOpenRefund = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openRefund: true, selectedOrder: order });
  };

  const onCloseRefund = (order) => {
    setState({ openRefund: false, selectedOrder: null });
  };
  const onOpenOrderEdit = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openOrderEdit: true, selectedOrder: order });
  };

  const onCloseOrderEdit = (order) => {
    setState({ openOrderEdit: false, selectedOrder: null });
  };
  const onOpenAssignCourier = (order) => {
    if (!order) {
      message.error('No order selected');
      return;
    }
    setState({ openAssignCourier: true, selectedOrder: order });
  };

  const onCloseAssignCourier = () => {
    setState({ openAssignCourier: false, selectedOrder: null });
  };
  const onOpenCustomerInfo = (customer_id,order_id) => {
    
    setState({ openCustomerInfo: true, order_id: order_id,customer_id });
  };
  const onCloseCustomerInfo = () => {
    setState({ openCustomerInfo: false, customer_id: undefined });
  };
  const onOpenDrawerChat = (task, chatWith) => {
    if (task.issue_source_type == 'order') {
      switch (chatWith) {
        case 'merchant':
          setState({
            roomRef: task.merchantOrderRoomRef || task.issue_source_id,
            roomType: task.merchantOrderRoomType || 'merchant_agent',
          });
          break;
        case 'customer':
          setState({
            roomRef: task.customerOrderRoomRef || task.issue_source_id,
            roomType: task.customerOrderRoomType || 'customer_agent',
          });
          break;
        case 'driver':
          setState({
            roomRef: task.driverOrderRoomRef || task.issue_source_id,
            roomType: task.driverOrderRoomType || 'orders',
          });
          break;
        default:
          break;
      }
    } else if (task.issue_source_type == 'driver') {
      setState({
        roomRef: task.driverHelpCenterRoomRef || task.issue_source_id,
        roomType: task.driverHelpCenterRoomType || 'couriers',
        roomName: 'roomName',
        _id: 'unknown',
      });
    } else if (task.issue_source_type == 'merchant') {
      setState({
        roomRef: task.issue_source_id || task.issue_source_id,
        roomType: 'merchant_agent',
        roomName: 'roomName',
        _id: 'unknown',
      });
    } else if (task.issue_source_type == 'customer') {
      setState({
        roomRef: task.customerHelpCenterRoomRef || task.issue_source_id,
        roomType: task.customerHelpCenterRoomType || 'customer_general',
      });
    }
    setState({ openDrawerChat: true, selectedOrder: task });
  };

  const onCloseOpenDrawerChat = (order) => {
    setState({ openDrawerChat: false, selectedOrder: null });
  };
  const copyToClipboard = (text) => {
    window.navigator.clipboard.writeText(text);
    message.success('Copied to clipboard');
  };
  const onOpenSubTaskModal = (task, texts) => {
    setState({
      openSubTaskModal: true,
      selectedSubTasks: task,
      statusTexts: texts,
    });
  };
  const onCloseSubTaskModal = () => {
    setState({ openSubTaskModal: false, selectedSubTasks: [] });
  };
  const onOpenTaskDrawer = (task) => {
    setState({ openTaskDrawer: true, selectedTask: task });
  };
  const onCloseTaskDrawer = () => {
    setState({ openTaskDrawer: false, selectedTask: null });
  };

  const onCloseFilterDrawer = (childState) => {
    setState({ ...childState, openFilterDrawer: false });
    getTasks(childState);
  };

  const onOpenFilterDrawer = (merchantId) => {
    setState({
      openFilterDrawer: true /* merchantIds: states.merchantIds === merchantId ? null : merchantId */,
    });
  };

  const onChangeLanguage = (language) => {
    localStorage.setItem('language', language);
    setState({ language });
  };

  const onOpenDriverInfo = (driver) => {
    setState({ openDriverInfo: true, driver: driver });
  };
  const onCloseDriverInfo = () => {
    setState({ openDriverInfo: false, driver: null });
  };

  const onOpenTicketModal = () => {
    setState({ isModalVisible: true });
  };

  const onCloseTicketModal = () => {
    setState({ isModalVisible: false });
  };

  const extraProps = {
    onOpenOrderCart,
    onOpenRefund,
    onOpenOrderEdit,
    onOpenChangeOrderStatusModal,
    onOpenOrderMap,
    onOpenEditProductIssueModal,
    changeTaskStatus,
    onOpenDrawerChat,
    getTaskLoading,
    changeTaskLoading,
    onOpenAssignCourier,
    onOpenMerchantInfo,
    copyToClipboard,
    isGrouped: dataTasks?.isGrouped,
    onOpenTaskDrawer,
    onOpenCustomerInfo,
    sendCommend,
    sendCommentLoading,
    onOpenDriverInfo,
    dayjs,
    onOpenEditProductIssueModal,
    onOpenTicketModal,
    onCloseTicketModal,
  };

  const renderTodo = (task, index) => {
    if (dataTasks?.isGrouped) {
      return (
        <RenderGroupedTasks
          key={`${task.group_id}_todo`}
          task={task}
          statusText={'In Progress'}
          statusCode={'todo'}
          onOpenDrawerChat={onOpenDrawerChat}
          onOpenSubTaskModal={onOpenSubTaskModal}
          copyToClipboard={copyToClipboard}
        />
      );
    }

    return (
      <RenderTaskWithChat
        key={`${task.operation_task_id} ${task.issue_source_type} # ${task.issue_source_id}_todo`}
        task={task}
        index={index}
        statusText={'Done'}
        statusCode={'in_progress'}
        StatusIcon={ToInProgress}
        statusColor={'#f9b04b'}
        {...extraProps}
      />
    );
  };

  const renderDone = (task, index) => {
    if (dataTasks?.isGrouped) {
      return (
        <RenderGroupedTasks
          key={`${task.group_id}_done`}
          task={task}
          statusText={'Done'}
          statusCode={'done'}
          onOpenDrawerChat={onOpenDrawerChat}
          onOpenSubTaskModal={onOpenSubTaskModal}
          copyToClipboard={copyToClipboard}
        />
      );
    }

    return (
      <RenderTaskWithChat
        key={`${task.operation_task_id} ${task.issue_source_type} # ${task.issue_source_id}_done`}
        task={task}
        index={index}
        statusText={'Done'}
        statusCode={'done'}
        StatusIcon={ToInProgress}
        statusColor={'#f9b04b'}
        {...extraProps}
      />
    );
  };

  return (
    <div className={classes.mainDev}>
      <div className={classes.taskBoardContainer}>
        <Row gutter={6} className={classes.statusRow} justify={'space-between'} >
          <Col span={12}>
            <Search onChange={handleSearch} />
          </Col>

          <Col span={6}>
            <Button onClick={onOpenTicketModal} style={{ width: '75%' }} type='primary'>
              Show Done Tasks
            </Button>
          </Col>
        </Row>

        <div
          style={{ height: 'calc(100dvh - 150px)' }}
          className={classes[`board-container`]}
        >
          <div span={6} className={classes.taskColumn}>
            <div
              className={`${classes.stickyHeader} ${classes.assignedHeader}`}
            >
              <Row justify={'space-between'}>
                <Col>
                  <h3>To Do</h3>
                </Col>
                <Col className={classes['assign-count']}>
                  {dataTasks?.todo?.length}
                </Col>
              </Row>
            </div>
            <div className={classes.columnContainer}>
              <List>
                <VirtualList
                  onScroll={(event) => {
                    event.stopPropagation();
                  }}
                  data={dataTasks?.todo}
                  height={window.innerHeight - 220}
                  itemHeight={dataTasks?.isGrouped ? 100 : 230}
                  itemKey={
                    dataTasks?.isGrouped ? 'group_id' : 'operation_task_id'
                  }
                >
                  {renderTodo}
                </VirtualList>
              </List>
            </div>
          </div>
        {states.isModalVisible?  <CompletedTickets
            isOpen={states.isModalVisible}
            onClose={onCloseTicketModal}
          
            renderDone={renderDone}
          />:null}
        </div>

        {states.openChangeOrderStatusModal && (
          <ChangeOrderStatusModalComponent
            status={states.openChangeOrderStatusModal}
            setStatus={onCloseChangeOrderStatusModal}
            selectedOrder={states.selectedOrder}
            getAllOrders={() => {}}
            ordersSearchFilters={{}}
          />
        )}
        {states.openOrderMap && (
          <OrderMapModalComponent
            status={states.openOrderMap}
            setStatus={onCloseOrderMap}
            selectedOrder={states.selectedOrder}
            getAllOrders={() => {}}
            ordersSearchFilters={{}}
          />
        )}
        {states.openOrderCart && (
          <OrderCartModalComponent
            status={states.openOrderCart}
            setStatus={onCloseOrderCart}
            selectedOrder={states.selectedOrder}
            getAllOrders={() => {}}
            ordersSearchFilters={{}}
          />
        )}
        {states.openRefund && (
          <OrderRefundModal
            status={states.openRefund}
            setStatus={onCloseRefund}
            selectedOrder={states.selectedOrder}
          />
        )}
        {states.openOrderEdit && (
          <EditOrderModal
            status={states.openOrderEdit}
            setStatus={onCloseOrderEdit}
            selectedOrder={states.selectedOrder}
            getAllOrders={() => {}}
            ordersSearchFilters={{}}
          />
        )}
        {states.openAssignCourier && (
          <AssignCourierModal
            orderId={states.selectedOrder.orderId}
            courierSystemOrderId={states.selectedOrder.courierSystemOrderId}
            cityCode={states.selectedOrder.cityCode}
            assignedDriver={states.selectedOrder.assignedDriver}
            orderLatitude={states.selectedOrder.orderLatitude}
            orderLongitude={states.selectedOrder.orderLongitude}
            getAllOrders={() => {}}
            onCancel={onCloseAssignCourier}
          />
        )}
        {states.openDrawerChat && (
          <DrawerChat
            open={states.openDrawerChat}
            onClose={onCloseOpenDrawerChat}
            roomRef={states.roomRef}
            roomType={states.roomType}
            roomName={states.roomName}
            height={'100%'}
          />
        )}

        {states.openMerchantModel && (
          <MerchantInformationModal
            status={states.openMerchantModel}
            setStatus={onCloseMerchantInfo}
            selectedOrder={states.selectedOrder}
          />
        )}
        {states.openSubTaskModal && (
          <SubTaskModal
            selectedSubTasks={states.selectedSubTasks}
            openSubTaskModal={states.openSubTaskModal}
            onCloseSubTaskModal={onCloseSubTaskModal}
            statusTexts={states.statusTexts}
            extraProps={extraProps}
            getTasks={getTasks}
          />
        )}
        {states.openTaskDrawer && (
          <TaskDrawer
            task={states.selectedTask}
            onClose={onCloseTaskDrawer}
            statusText={'In Progress'}
            statusCode={'todo'}
            StatusIcon={ToInProgress}
            mediaData={dataTasks?.media}
            {...extraProps}
          />
        )}

        {/* {states.openFilterDrawer && (
          <FilterDrawer
            onCloseFilterDrawer={onCloseFilterDrawer}
            states={states}
            tasks={dataTasks?.todo}
          />
        )} */}
        {states.openCustomerInfo && (
          <CustomerInformationModal
            customer_id={states.customer_id}
            order_id={states.order_id}
            onClose={onCloseCustomerInfo}
            open={states.openCustomerInfo}
          />
        )}
        {states.openEditProductIssueModal && (
          <EditProductIssueModal
            selectedProduct={states.selectedProduct}
            open={states.openEditProductIssueModal}
            onClose={() => {
              setState({
                openEditProductIssueModal: false,
                selectedOrder: null,
              });
            }}
          />
        )}
        {states.openDriverInfo && (
          <DriverInformationModal
            driver={states.driver}
            open={states.openDriverInfo}
            onCloseDriverInfo={onCloseDriverInfo}
          />
        )}
      </div>
    </div>
  );
};

export default TicketOnChat;




import { Button, Tooltip, message } from 'antd';

import {
  UilClipboardNotes,
  UilEdit,
  UilMap,
  UilShoppingCartAlt,
  UilUsdCircle,
  UilTimesCircle,
} from '@iconscout/react-unicons';

import { BellOutlined, CarOutlined } from '@ant-design/icons';

const RenderMenu = ({
  onOpenChangeOrderStatusModal,
  currentOrder,
  onOpenOrderMap,
  onOpenOrderCart,
  onOpenRefund,
  onOpenOrderEdit,
  onOpenAssignCourier,
  onOpenMerchantInfo,
}) => {
  if (!currentOrder) {
    return null;
}
  return (
    <div>
      <div>
        <div
          style={{
            display: 'flex',
            gap: '5px',
          }}
        >
          <Tooltip title="Change Status">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenChangeOrderStatusModal(currentOrder);
              }}
              type="primary"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<UilClipboardNotes size="22" />}
            />
          </Tooltip>
          <Tooltip title="Map View">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenOrderMap(currentOrder);
              }}
              style={{
                background: '#e67e22',
                borderColor: '#e67e22',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<UilMap size="22" />}
            />
          </Tooltip>
          <Tooltip title="Order Cart">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenOrderCart(currentOrder);
              }}
              style={{
                background: '#16a085',
                borderColor: '#16a085',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<UilShoppingCartAlt size="22" />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Edit Order">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenOrderEdit(currentOrder);
              }}
              style={{
                background: '#c0392b',
                borderColor: '#c0392b',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<UilEdit size="22" />}
            />
          </Tooltip>
        </div>
        <div
          style={{
            width: '80%',
            height: 1,
            backgroundColor: '#00000033',
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: 'flex',
            gap: '5px',
          }}
        >
          <Tooltip placement="bottom" title="Assign Rider">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenAssignCourier(currentOrder);
              }}
              style={{
                background: '#EE6758',
                borderColor: '#EE6758',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<CarOutlined size="22" />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Compensate/Refund">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenRefund(currentOrder);
              }}
              style={{
                background: '#8e44ad',
                borderColor: '#8e44ad',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<UilUsdCircle size="22" />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Merchant Info">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onOpenMerchantInfo(currentOrder);
              }}
              style={{
                background: '#c0392b',
                borderColor: '#c0392b',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<UilTimesCircle size="22" />}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Send Notification">
            <Button
              loading={false}
              // loading={loadingSendingMerchantNotification}
              onClick={(e) => {
                e.stopPropagation();
                if (!currentOrder) {
                  message.error('No order selected');
                  return;
                }
                const merchant_name = currentOrder.merchant.merchant_name.en;
                const merchant_id = currentOrder.merchant.merchant_id;
                const order_id = currentOrder.orderId;

                requestSendingMerchantNotification({
                  merchant_id,
                  merchant_name,
                  order_id,
                });
              }}
              style={{
                backgroundColor: '#e67e2255',
                color: '#e67e22',
                width: 35,
              }}
              icon={<BellOutlined />}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default RenderMenu;

import { useContext, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Col,
  Row,
  Table,
  Tag,
  Tooltip,
  Steps,
  message,
  Badge,
  Image,
  Popconfirm,
} from 'antd';
import {
  UilMap,
  UilClipboardNotes,
  UilUsdCircle,
  UilCheckCircle,
  UilClock,
  UilSetting,
  UilCommentAltLines,
  UilShoppingCartAlt,
  UilCarSideview,
  UilEdit,
} from '@iconscout/react-unicons';
import { OrderContext } from '../../pages/Home';
import { useAxiosPost, useAxiosPut } from '../../configs/axios';
import {
  BellOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  CarOutlined,
} from '@ant-design/icons';
import ResolveOrderModal from './ResolveOrderModal';
const { Step } = Steps;

const OrdersTableComponent = (props) => {
  const [openOrderResolveModal, setOpenOrderResolveModal] = useState();
  const { loading: loadingAutoAssign, request: requestAutoAssign } =
    useAxiosPost('/orders/bestRider');
  const {
    loading: loadingSendingMerchantNotification,
    request: requestSendingMerchantNotification,
  } = useAxiosPost('/orders/send_notification_to_merchant');
  const {
    loading: loadingRemovingVipFormOrder,
    request: requestRemovingVipFormOrder,
  } = useAxiosPost('/vip/removeVipInOrder');

  const { loading: loadingUpdateToCourier, request: requestUpdateToCourier } =
    useAxiosPut('/orders/update_courier_status');

  const {
    ordersData,
    ordersError,
    ordersLoading,
    getAllOrders,
    ordersSearchFilters,
    setOrdersSearchFilters,
    selectedOrder,
  } = useContext(OrderContext);
  const {
    setInvoiceModalVisible,
    // Modals
    openOrderStatusChangeModal,
    openOrderMapModal,
    openOrderCartModal,
    openCustomerInformationModal,
    openMerchantInformationModal,
    openDriverInformationModal,
    openEditOrderModal,
    openOrderAmountDetailsModal,
    openOrderRefundModal,
    openOrderEvaluationModal,
    openOrderChatModal,
    openCustomerChatModal,
  } = props;
  const {
    loading: updateOrderStatusLoading,
    request: updateOrderStatusRequest,
  } = useAxiosPut('/orders/status');
  const convertPayment = async (orderId) => {
    try {
      await updateOrderStatusRequest({ statusId: 25, orderId });
      message.success('Order converted to paid successfully');
      getAllOrders();
    } catch (error) {
      message.error(error?.response?.data?.msg||"Error converting order to paid");
    }
  };
  const showOrderPaymentType = (orderTypeId) => {
    switch (orderTypeId) {
      case 1:
        return 'Fastpay';
      case 3:
        return 'LezzooPay';
      case 4:
        return 'Credit Card';
      case 5:
        return 'Zain Cash';
      case 6:
        return 'Asia Hawala';
      case 7:
        return 'NassPay';
      case 11:
        return 'FIB';
      default:
        return null;
    }
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'orderId',
      key: 'orderId',
      width: '1%',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Badge
            overflowCount={99}
            color="grey"
            offset={[0, 30]}
            count={
              record.chatMerchantStatus !== 'opened'
                ? 0
                : record.countMerchantMessages
                ? record.countMerchantMessages
                : 0
            }
          >
            <Badge
              overflowCount={99}
              color="red"
              offset={[-30, 0]}
              count={
                record.chatStatus !== 'opened'
                  ? 0
                  : record.countMessages
                  ? record.countMessages
                  : 0
              }
            >
              <Badge
                overflowCount={99}
                color="#10a30b"
                count={
                  record.chatCustomerStatus !== 'opened'
                    ? 0
                    : record.countCustomerMessages
                    ? record.countCustomerMessages
                    : 0
                }
              >
                <Tooltip title="Messages">
                  <Button
                    onClick={() => openOrderChatModal(record)}
                    style={{
                      background: record.orderEvaluationColorFlag
                        ? record.orderEvaluationColorFlag
                        : '#2c3e50',
                      borderColor: record.orderEvaluationColorFlag
                        ? record.orderEvaluationColorFlag
                        : '#2c3e50',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    icon={<UilCommentAltLines size="22" />}
                  />
                </Tooltip>
              </Badge>
            </Badge>
          </Badge>
          {record.orderEvaluationFlags && (
            <div
              style={{
                height: '8px',
                width: '30px',
                borderRadius: '20px',
                opacity: '0.8',
                cursor: 'pointer',
                backgroundColor: record.orderEvaluationColorFlag
                  ? record.orderEvaluationColorFlag
                  : 'transparent',
              }}
              onClick={() =>
                record.orderEvaluationFlags.length > 0 &&
                openOrderEvaluationModal(record)
              }
            />
          )}
          <Tooltip
            title={
              <Button
                type="primary"
                onClick={() =>
                  window.open(
                    `${window.location.origin}/order-details?oid=${record?.orderId}`,
                    '_blank',
                    'noopener,noreferrer',
                  )
                }
              >
                Show Order Detail
              </Button>
            }
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(text);
                message.success('Copied to clipboard');
              }}
            >
              {text}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      width: '2%',
      render: (text, record) => (
        <>
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
            }}
          >
            <UilClock size={20} />
            <p style={{ marginLeft: '5px' }}>
              {(Math.ceil(
                moment
                  .duration(moment().diff(moment(record.createdAt)))
                  .asMinutes(),
              ) || 0) + ' mins'}
            </p>
          </div>
          {record.orderRefundAmount ? (
            <Tag
              style={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                marginBottom: '5px',
              }}
              color={'red'}
            >
              Order Refund {record.orderRefundAmount}
            </Tag>
          ) : (
            ''
          )}
          <Tag
            style={{
              fontWeight: 'bold',
              textTransform: 'capitalize',
              marginBottom: '5px',
              display:
                record.lastStatusData &&
                !['completed', 'canceled'].includes(record.orderStatus)
                  ? 'none'
                  : null,
            }}
            color={
              text === 'canceled'
                ? 'red'
                : text === 'completed'
                ? 'green'
                : text === 'ontheway'
                ? 'yellow'
                : 'blue'
            }
          >
            {text}
          </Tag>

          {record.lastStatusData &&
          !['completed', 'canceled'].includes(record.orderStatus) ? (
            <div
              style={{
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              <Tag
                style={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  marginBottom: '5px',
                  color: 'black',
                }}
                color={'#B2FFA3'}
              >
                {record.lastStatusData.order_change_to +
                  ` (${record.lastStatusData.order_status_min})`}
              </Tag>
            </div>
          ) : null}

          {record.orderCompletedAt ? (
            <Tag color={record.orderRejectedOnType ? 'red' : 'green'}>
              {(record.orderRejectedOnType
                ? '🚫 Order Rejected since 🚫: '
                : 'Order Completed since: ') +
                (Math.ceil(
                  moment
                    .duration(moment().diff(moment(record.orderCompletedAt)))
                    .asMinutes(),
                ) || 0) +
                ' mins'}
            </Tag>
          ) : record.orderCanceledAt ? (
            <Tag color="red">
              {'❌ Order Canceled since ❌: ' +
                (Math.ceil(
                  moment
                    .duration(moment().diff(moment(record.orderCanceledAt)))
                    .asMinutes(),
                ) || 0) +
                ' mins'}
            </Tag>
          ) : record.orderReadyAt ? (
            record.orderStatus === 'ontheway' ? null : (
              <Tag color="red">
                {'✅ Order is ready! ✅: ' +
                  (Math.ceil(
                    moment
                      .duration(moment().diff(moment(record.orderReadyAt)))
                      .asMinutes(),
                  ) || 0) +
                  ' mins'}
              </Tag>
            )
          ) : record.orderEstReadyAt ? (
            <Tag
              color={
                (Math.ceil(
                  moment
                    .duration(moment(record.orderEstReadyAt).diff(moment()))
                    .asMinutes(),
                ) || 0) >= 0
                  ? 'blue'
                  : 'red'
              }
            >
              {((Math.ceil(
                moment
                  .duration(moment(record.orderEstReadyAt).diff(moment()))
                  .asMinutes(),
              ) || 0) >= 0
                ? '⏳Will be ready at⏳: '
                : '🙀Merchant is late🙀: ') +
                ((Math.abs(
                  Math.ceil(
                    moment
                      .duration(moment(record.orderEstReadyAt).diff(moment()))
                      .asMinutes(),
                  ),
                ) || 0) +
                  ' mins')}
            </Tag>
          ) : null}

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
            }}
          >
            {record.orderTags?.map((flag, index) => {
              const name = flag?.name?.trim();
              //// this part of code will only be executed when the moused hover triggered
              const isMerchantHasComment = name == 'Merchant has comment!';
              const showCommentTitle = () => {
                let comments = '';
                if (isMerchantHasComment) {
                  record.orderStatusChangeHistory?.map((history) => {
                    if (
                      history.order_change_source == 'Merchant APP' &&
                      history.order_change_to_id > 10 &&
                      history.order_change_to_id != 42
                    ) {
                      // [3, 4, 6, 7, 8, 9, 10, 11]
                      history.order_change_to == 'merchant_order_commented'
                        ? (comments += `\n (${history.order_extra_to})`)
                        : (comments += `\n ${history.order_change_to}`);
                    }
                  });
                  comments += '\n' + record.orderComment;
                }
                return comments.trim() ? comments : undefined;
              };
              return (
                <Tooltip
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/order-details?oid=${record?.orderId}`,
                      '_blank',
                      'noopener,noreferrer',
                    );
                  }}
                  destroyTooltipOnHide
                  mouseEnterDelay={'.2'}
                  title={isMerchantHasComment ? showCommentTitle : undefined}
                >
                  <Tag
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      marginBottom: '5px',
                    }}
                    color={flag.color}
                    key={index}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: `<div>${name}</div>` }}
                    ></div>
                  </Tag>
                </Tooltip>
              );
            })}
          </div>
        </>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      width: 100,
      // 4364463
      render: (customer, record) => {
        return (
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            {record.orderSubscriptionTotal ? (
              <Popconfirm
                title={'are you sure you want to remove vip form this order ?'}
                okText="yes"
                cancelText="No"
                onConfirm={() => {
                  requestRemovingVipFormOrder({
                    orderId: record.orderId,
                    customerId: record.customer.customer_id,
                    orderLatitude:
                      record.currentCustomerLocation?.coordinates[0],
                    orderLongitude: [1],
                  });
                }}
              >
                <Button
                  type="primary"
                  style={{
                    backgroundColor: 'red',
                  }}
                  shape="circle"
                  size="small"
                  loading={loadingRemovingVipFormOrder}
                  icon={<CloseCircleOutlined size={10} />}
                />
              </Popconfirm>
            ) : null}

            {record.customer?.customerSubscriptionData ? (
              <Tag
                style={{
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  borderColor: 'orange',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  openCustomerInformationModal(record);
                }}
                color={'yellow'}
              >
                <span style={{ whiteSpace: 'nowrap', color: 'black' }}>
                  👑{' '}
                  {record.customer.customer_name +
                    ' ' +
                    record.customer.customer_last_name}
                </span>
              </Tag>
            ) : (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  openCustomerInformationModal(record);
                }}
              >
                {record.customer.customer_name +
                  ' ' +
                  record.customer.customer_last_name}
              </a>
            )}
            {record.customer?.customersTags?.length > 0 ? (
              <Row gutter={[0, 5]}>
                {record.customer.customersTags.map((tag, index) => {
                  return (
                    <Tag color={tag.color} key={index}>
                      {tag.name}
                    </Tag>
                  );
                })}
              </Row>
            ) : null}
          </div>
        );
      },
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      key: 'merchant',
      width: 200,
      render: (text, record) => (
        <Row
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Col
            style={{
              width: '65px',
            }}
          >
            <Image
              src={record.merchant.merchant_logo}
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                marginRight: 10,

                border: record.merchant?.merchantVipData
                  ? '3px solid orange'
                  : undefined,
              }}
            />
          </Col>
          <Col span={16}>
            <Row style={{ marginBottom: '10px' }} wrap="flex-wrap">
              <Col span={24}>
                <Button
                  loading={loadingSendingMerchantNotification}
                  onClick={() => {
                    const merchant_name = record.merchant.merchant_name.en;
                    const merchant_id = record.merchant.merchant_id;
                    const order_id = record.orderId;
                    requestSendingMerchantNotification({
                      merchant_id,
                      merchant_name,
                      order_id,
                    });
                  }}
                  style={{
                    backgroundColor: '#e67e2255',
                    color: '#e67e22',
                    width: 25,
                  }}
                  icon={<BellOutlined />}
                />
              </Col>
              <Col span={24}>
                {record.merchant?.merchantVipData ? (
                  <Tag
                    style={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      borderColor: 'orange',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      openMerchantInformationModal(record);
                    }}
                    color={'yellow'}
                  >
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      👑 {record.merchant.merchant_name.en}
                    </span>
                  </Tag>
                ) : (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      openMerchantInformationModal(record);
                    }}
                  >
                    {record.merchant.merchant_name.en}
                  </a>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'driver',
      width: 200,
      render: (text, record) =>
        record.driver ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                openDriverInformationModal(record);
              }}
            >
              {record.driver.firstName} {record.driver.lastName}
            </a>
            {/* {record.orderStatusId && (
              <span>
                {record.orderStatusId === 8 ? (
                  <Tag color="red"> To Pick Up</Tag>
                ) : record.orderStatusId === 9 ? (
                  <Tag color="yellow"> At Pick Up</Tag>
                ) : record.orderStatusId === 10 ? (
                  <Tag color="blue"> To Drop Off</Tag>
                ) : record.orderStatusId === 11 ? (
                  <Tag color="green"> At Drop Off</Tag>
                ) : (
                  ""
                )}
              </span>
            )} */}
          </div>
        ) : record.orderCanBeDispatched ? (
          <Button
            onClick={() => {
              if (
                !record.courierSystemOrderId ||
                !record.courierSystemOrderId.toString().length
              ) {
                message.error(
                  'Please send the order to courier before dispatching it!',
                );
              } else {
                onDriverAutoAssign(record.courierSystemOrderId);
              }
            }}
            type="primary"
            loading={loadingAutoAssign}
          >
            Auto Assign
          </Button>
        ) : (
          'No Driver'
        ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 200,
      render: (text, record) => (
        <span
          onClick={() => {
            navigator.clipboard.writeText(
              `${record.orderCity} ${record.orderAddress}`,
            );
            message.success('Copied to clipboard');
          }}
          style={{
            // display: 'inline-block',
            // maxWidth: '130px',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // maxLines: 1,
            cursor: 'pointer',
            // textOverflow: 'ellipsis',
          }}
        >
          {record.orderCity} {record.orderAddress}
        </span>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {record.orderPaymentTypeId !== 0 &&
            record.orderPaymentTypeId !== 2 && (
              <Popconfirm
                title="Do you want to convert payment to cash?"
                onConfirm={() => convertPayment(record.orderId)}
              >
                <Button
                  style={{
                    color: record.orderPaymentId ? '#16a085' : '#c0392b',
                  }}
                >
                  {' '}
                  {showOrderPaymentType(record.orderPaymentTypeId)}
                </Button>
              </Popconfirm>
            )}
          <Button
            onClick={(e) => {
              e.preventDefault();
              openOrderAmountDetailsModal(record);
            }}
          >
            {Number(
              (record.orderTotal || 0) + (record.orderSubscriptionTotal || 0),
            ).toLocaleString('en-US')}
          </Button>
        </div>
      ),
    },

    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      render: (text, record) => (
        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          {record?.orderAssignedToAgents?.length > 0 ? (
            <Badge
              count={
                record?.orderAssignedToAgents.filter((item) => !item.solve)
                  .length
              }
            >
              <Button
                icon={<CheckCircleOutlined />}
                type="primary"
                style={{
                  backgroundColor: '#5cb85c	',
                }}
                onClick={() => {
                  setOpenOrderResolveModal(record);
                }}
              />
            </Badge>
          ) : null}
          {record.orderAssignedToAgent ? (
            record.orderAssignedToAgent.userName
          ) : (
            <Tag
              color="red"
              style={{
                textAlign: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              Not Assigned
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Courier',
      dataIndex: 'sendToCourier',
      key: 'sendToCourier',
      width: 20,
      render: (text, record) => (
        <Popconfirm
          title="Are you want to Update Courier Status?"
          onConfirm={() => {
            requestUpdateToCourier({
              orderId: record.orderId,
              message: 'Force send To Courier By ME',
              source: 'Operation',
            })
              .then(() => {
                message.success('Successfully Update');
              })
              .catch((e) => {
                message.error('Order Not Found');
              });
          }}
        >
          <Button type="primary" icon={<CarOutlined />} />
        </Popconfirm>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (actions, record) => (
        <div>
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Tooltip title="Change Status">
              <Button
                onClick={() => openOrderStatusChangeModal(record)}
                type="primary"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={<UilClipboardNotes size="22" />}
              />
            </Tooltip>
            <Tooltip title="Map View">
              <Button
                onClick={() => openOrderMapModal(record)}
                style={{
                  background: '#e67e22',
                  borderColor: '#e67e22',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={<UilMap size="22" />}
              />
            </Tooltip>
            <Tooltip title="Order Cart">
              <Button
                onClick={() => openOrderCartModal(record)}
                style={{
                  background: '#16a085',
                  borderColor: '#16a085',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={<UilShoppingCartAlt size="22" />}
              />
            </Tooltip>
          </div>
          <div
            style={{
              width: '80%',
              height: 1,
              backgroundColor: '#00000033',
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <Tooltip placement="bottom" title="Invoice">
              <Button
                onClick={() => setInvoiceModalVisible(record)}
                style={{
                  background: '#c0392b',
                  borderColor: '#c0392b',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={<FilePdfOutlined size="22" />}
              />
            </Tooltip>

            <Tooltip placement="bottom" title="Compensate/Refund">
              <Button
                onClick={() => openOrderRefundModal(record)}
                style={{
                  background: '#8e44ad',
                  borderColor: '#8e44ad',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={<UilUsdCircle size="22" />}
              />
            </Tooltip>

            <Tooltip placement="bottom" title="Edit Order">
              <Button
                onClick={() => openEditOrderModal(record)}
                style={{
                  background: '#c0392b',
                  borderColor: '#c0392b',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={<UilEdit size="22" />}
              />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  const onDriverAutoAssign = async (orderId) => {
    await requestAutoAssign({
      orderId,
    });
    message.success('Driver assigned successfully!');
  };

  return (
    <>
      {openOrderResolveModal ? (
        <ResolveOrderModal
          setOpen={setOpenOrderResolveModal}
          open={openOrderResolveModal}
        />
      ) : null}
      <Table
        bordered
        columns={columns}
        locale={{
          emptyText: ordersError ? ordersError.message : 'No Orders',
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Row justify="center">
              <Col span={23}>
                <Steps className="site-navigation-steps">
                  <Step
                    title="Pending"
                    description={moment(record.orderDateAdded).format(
                      'hh:mm A',
                    )}
                    status={record.orderDateAdded ? 'finish' : 'process'}
                    icon={<UilClock size={20} />}
                  />
                  <Step
                    title="Processing"
                    description={moment(record.orderProcessedAt).format(
                      'hh:mm A',
                    )}
                    status={record.orderProcessedAt ? 'finish' : 'process'}
                    icon={<UilSetting size={20} />}
                  />
                  <Step
                    title="Prepared"
                    icon={<UilCheckCircle size={20} />}
                    description={moment(record.orderReadyAt).format('hh:mm A')}
                    status={record.orderReadyAt ? 'finish' : 'process'}
                  />
                  <Step
                    title="On The Way"
                    description={moment(record.orderOnthewayAt).format(
                      'hh:mm A',
                    )}
                    status={record.orderOnthewayAt ? 'finish' : 'process'}
                    icon={<UilCarSideview size={20} />}
                  />
                  <Step
                    title="Complete"
                    description={
                      record.orderCompletedAt
                        ? moment(record.orderCompletedAt).format('hh:mm A')
                        : 'EST: ' +
                          moment(record.orderEstReadyAt).format('hh:mm A')
                    }
                    status={record.orderCompletedAt ? 'finish' : 'wait'}
                    icon={<UilCheckCircle size={20} />}
                  />
                </Steps>
              </Col>
            </Row>
          ),
          rowExpandable: () => true,
        }}
        dataSource={ordersData?.data}
        rowKey={(record) => record.orderId}
        rowClassName={(record, index) =>
          selectedOrder && selectedOrder.orderId == record.orderId
            ? 'selected-order-row'
            : null
        }
        pagination={{
          current: ordersData?.currentPage,
          total: ordersData?.records,
          pageSize: 25,
          showSizeChanger: false,
          onChange: (page) => {
            const newSearchFilters = {
              ...ordersSearchFilters,
              page,
            };
            setOrdersSearchFilters(newSearchFilters);
            getAllOrders(newSearchFilters);
          },
          // onShowSizeChange: (current, size) => {
          //   const newSearchFilters = {
          //     ...ordersSearchFilters,
          //     page: current,
          //     limit: size,
          //   };
          //   setOrdersSearchFilters(newSearchFilters);
          //   getAllOrders(newSearchFilters);
          // },
        }}
      />
    </>
  );
};

export default OrdersTableComponent;

import classes from './CustomerandDriverDisplay.module.css'; // Import your CSS module

import VipIcon from './icons/VipIcon';
import Customer from './icons/Customer';
import Driver from './icons/Driver';
import ChatIcon from './icons/ChatIcon';
import { Button, Tooltip } from 'antd';
import Chat from './icons/Chat';
import Phone from './icons/Phone';

const ChatCounter = 0;

// Define the CustomerAndDriverDisplay component
const CustomerAndDriverDisplay = ({
  task,
  onOpenCustomerInfo,
  onOpenDrawerChat,
  onOpenDriverInfo,
  copyToClipboard
}) => {
  const { customer, driver,issue_source_id ,issue_source_type} = task;
  return (
    <div className={classes.Row}>
      {customer && (
        <Tooltip
          title={
            <div>
              <Button
                type="ghost"
                icon={
                  false ? (
                    <div>
                      {' '}
                      <div
                        className={classes.badgeContainer}
                        size="small"
                        count={5}
                      >
                        <Chat fill={'#F79C9C'} />
                        <span className={classes.badge}>2</span>
                      </div>
                    </div>
                  ) : (
                    <Chat fill={'#919191'} />
                  )
                }
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenDrawerChat(task, 'customer');
                }}
              />
              <Tooltip placement="top" title={customer?.source_phone}>
                <Button
                  type="ghost"
                  icon={<Phone fill={'#919191'} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(customer?.source_phone);

                  }}
                />
              </Tooltip>
            </div>
          }
        >
          <div
            className={`${classes['half-width']} ${task.customerOrderCountMessages ? classes.hasChat : ''
              }`}
          >
            <div className={classes.customer} onClick={(e) => {
              e.stopPropagation()
              console.log(task);
              
              onOpenCustomerInfo(customer?.source_id,issue_source_type=='order'?issue_source_id:undefined);
            }}>
              <div className={classes.customerIcons}>
                <Customer width={20} height={20} />
                <VipIcon width={22} height={22} />
              </div>
              <p
                className={classes['customer-paragraph']}

              >
                {customer?.source_name}
              </p>
            </div>
            {task.customerOrderCountMessages ? (
              <div className={classes['customer-chat-icon']}>
                <p>{task.customerOrderCountMessages}</p>
              </div>
            ) : null}
          </div>
        </Tooltip>
      )}
      {driver && (
        <Tooltip
          title={
            <div>
              <Button
                type="ghost"
                icon={<Chat fill={'#919191'} />}
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenDrawerChat(task, 'driver');
                }}
              />
              <Tooltip placement="top" title={driver?.source_phone}>
                <Button
                  type="ghost"
                  icon={<Phone fill={'#919191'} />}
                  onClick={(e) => {
                    e.stopPropagation();
                  
                    copyToClipboard(driver?.source_phone);
                  }}
                />
              </Tooltip>
            </div>
          }
        >
          <div
            className={`${classes['half-width']} ${task.driverOrderCountMessages ? classes.hasChat : ''
              }`}


              onClick={(e) => {
                e.stopPropagation();
              
                  onOpenDriverInfo(driver);
                
              }}
          >
            <Driver width={28} height={28} />
            <div className={classes.driver}>
              <p className={classes['driver-paragraph']}>
                {driver?.source_name}
              </p>
            </div>
            {task.driverOrderCountMessages ? (
              <div className={classes['customer-chat-icon']}>
                <p>{task.driverOrderCountMessages}</p>
              </div>
            ) : null}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomerAndDriverDisplay;

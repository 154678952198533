/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */

import {
  UilClipboardNotes,
  UilEdit,
  UilMap,
  UilShoppingCartAlt,
  UilUsdCircle,
} from '@iconscout/react-unicons';
import {
  Button,
  Card,
  Col,
  Image,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  message,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import '../../pages/chatStyle.css';

import {
  ClockCircleOutlined,
  DownOutlined,
  PhoneFilled,
  UpOutlined,
} from '@ant-design/icons';
import motor from '../../assets/images/motor.png';
import ChangeOrderStatusModalComponent from '../../components/home/ChangeOrderStatusModalComponent';
import CustomerInformationModal from '../../components/home/CustomerInformationModal';
import CustomerVIPModal from '../../components/home/CustomerVIPModal';
import EditOrderModal from '../../components/home/EditOrderModal';
import OrderCartModalComponent from '../../components/home/OrderCartModalComponent';
import OrderMapModalComponent from '../../components/home/OrderMapModalComponent';
import OrderRefundModal from '../../components/home/OrderRefundModal';
import { useAxiosPut } from '../../configs/axios';

import {
  statusTiming,
  getLastCurrentStatus,
  getColor,
  showOrderPaymentType,
} from '../../components/Chat/orderStatusConfig';

const format = 'hh:mm A';

function SingleOrderCard({ single }) {
  const [changeOrderStatusModalVisible, setChangeOrderStatusModalVisible] =
    useState(false);
  const [orderCartModalVisible, setOrderCartModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [orderMapModalVisible, setOrderMapModalVisible] = useState(false);
  const [orderRefundModalVisible, setOrderRefundModalVisible] = useState(false);
  const [editOrderModalVisible, setEditOrderModalVisible] = useState(false);

  const [showCustomerInfoModal, setShowCustomerInfoModal] = useState(false);
  const [showVipModal, setShowModalVip] = useState(false);
  const [customer, setCustomer] = useState('');
  const [showCollapse, setShowCollapse] = useState(false);

  const openOrderStatusChangeModal = (order) => {
    setSelectedOrder(order);
    setChangeOrderStatusModalVisible(true);
  };
  const openOrderMapModal = (order) => {
    setSelectedOrder(order);
    setOrderMapModalVisible(true);
  };
  const openOrderRefundModal = (order) => {
    setSelectedOrder(order);
    setOrderRefundModalVisible(true);
  };
  const openOrderCartModal = (order) => {
    setSelectedOrder(order);
    setOrderCartModalVisible(true);
  };
  const openEditOrderModal = (order) => {
    setSelectedOrder(order);
    setEditOrderModalVisible(true);
  };

  const { request: updateOrderStatusRequest } = useAxiosPut('/orders/status');

  const convertPayment = async (orderId) => {
    try {
      await updateOrderStatusRequest({ statusId: 25, orderId });
      message.success('Order converted to paid successfully');
      getAllOrders();
    } catch (error) {
      message.error(error?.response?.data?.msg||"Error converting order to paid");
    }
  };
// test
  const statusData = (type, time, badgeContent, bgColor, color, index) => {
    return (
      <div
        direction="vertical"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        key={index}
      >
        <p style={{ textTransform: 'capitalize' }}>{type}</p>
        <p style={{ margin: 0, fontWeight: 'bold', fontSize: 12 }}>{time}</p>
        <span
          style={{
            //width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: bgColor,
            color: color,
            borderRadius: 50,
            padding: 5,
            minHeight: 0,
            maxHeight: 25,
          }}
        >
          {badgeContent}m
        </span>
      </div>
    );
  };

  const renderCurrentStatus = (status) => {
    // duration of status.time as minutes to render
    //status.time is this formar "2024-05-01T17:27:55.000Z"
    // use the timezone, use GMT+3
    const time = moment(status.time).format('YYYY-MM-DD HH:mm:ss');

    const duration = Math.ceil(
      moment.duration(moment().diff(moment(time))).asMinutes(),
    );
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 10,
          backgroundColor: status.bgColor,
          padding: '5px 15px',
          borderRadius: 50,
          color: status.textColor,
          lineHeight: 1.1,
        }}
      >
        {status.icon}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
            {status.title}
          </span>
          <span>{duration} mins</span>
        </div>
      </div>
    );
  };
  const RenderMiniCurrentStatus = ({ status, align }) => {
    if (
      status &&
      status.title &&
      status.time &&
      status.bgColor &&
      status.textColor
    ) {
      // duration of status.time as minutes to render
      //status.time is this formar "2024-05-01T17:27:55.000Z"
      // use the timezone, use GMT+3
      const time = moment(status.time).format('YYYY-MM-DD HH:mm:ss').toString();

      const duration = Math.ceil(
        moment.duration(moment().diff(moment(time))).asMinutes(),
      );
      return (
        <div
          style={{
            width: '100%',
            alignContent: align,
            alignItems: align,
            justifyContent: align,
            display: 'flex',
          }}
        >
          <span
            style={{
              alignSelf: align,
              alignItems: 'center',
              textAlign: 'center',
              alignContent: 'center',
              backgroundColor: status.bgColor,
              borderRadius: 30,
              color: status.textColor,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 2,
              paddingBottom: 2,
              width: 'auto',
            }}
          >
            <span style={{ fontWeight: 'light', textTransform: 'capitalize' }}>
              {status.title}:{' '}
              <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {duration}m
              </span>
            </span>
          </span>
        </div>
      );
    }
    return null;
  };

  const checkStatus = (single) => {
    const status = getLastCurrentStatus(single, 'main');

    if (status) {
      return renderCurrentStatus(status);
    } else {
      return null;
    }
  };

  const OrderChips = ({ single }) => {
    return (
      <Space>
        {single?.lastStatusData &&
        !['completed', 'canceled'].includes(single?.orderStatus) ? (
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
            }}
          >
            <Tag
              style={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                marginBottom: '5px',
                color: 'black',
              }}
              color="#B2FFA3"
            >
              {`${single?.lastStatusData.order_change_to}(${single?.lastStatusData.order_status_min})`}
            </Tag>
          </div>
        ) : null}

        {single?.orderCompletedAt ? (
          <Tag color={single?.orderRejectedOnType ? 'red' : 'green'}>
            {`${
              (single?.orderRejectedOnType
                ? '🚫 Rejected: '
                : '✅ Completed: ') +
              (Math.ceil(
                moment
                  .duration(moment().diff(moment(single?.orderCompletedAt)))
                  .asMinutes(),
              ) || 0)
            } mins`}
          </Tag>
        ) : single?.orderCanceledAt ? (
          <Tag color="red">
            {`❌ Canceled: ${
              Math.ceil(
                moment
                  .duration(moment().diff(moment(single?.orderCanceledAt)))
                  .asMinutes(),
              ) || 0
            } mins`}
          </Tag>
        ) : single?.orderReadyAt ? (
          single?.orderStatus === 'ontheway' ? null : (
            <Tag color="red">
              {`✅ Ready: ${
                Math.ceil(
                  moment
                    .duration(moment().diff(moment(single?.orderReadyAt)))
                    .asMinutes(),
                ) || 0
              } mins`}
            </Tag>
          )
        ) : single?.orderEstReadyAt ? (
          <Tag
            color={
              (Math.ceil(
                moment
                  .duration(moment(single?.orderEstReadyAt).diff(moment()))
                  .asMinutes(),
              ) || 0) >= 0
                ? 'blue'
                : 'red'
            }
          >
            {`${
              (Math.ceil(
                moment
                  .duration(moment(single?.orderEstReadyAt).diff(moment()))
                  .asMinutes(),
              ) || 0) >= 0
                ? '⏳ Est ready: '
                : '🙀 M late: '
            }${
              Math.abs(
                Math.ceil(
                  moment
                    .duration(moment(single?.orderEstReadyAt).diff(moment()))
                    .asMinutes(),
                ),
              ) || 0
            } mins`}
          </Tag>
        ) : null}
      </Space>
    );
  };

  const RenderDriverInfo = ({ single }) => {
    const currentShift = getDriverCurrentShift(single);
    const driverStatus = getLastCurrentStatus(single, 'driver');
    return single?.driver ? (
      <Col
        span={12}
        style={{
          alignContent: 'right',
          alignItems: 'right',
          justifyContent: 'right',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Image
            src={single?.driver?.avatarLink || motor}
            height={48}
            width={48}
            style={{
              borderRadius: '100%',
              objectFit: 'cover',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Space>
              <a
                style={{
                  color: '#FF9900',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(single?.driver?._id);
                  message.success('Copied to clipboard');
                }}
              >
                <Tooltip
                  title={
                    single?.driver?.firstName + ' ' + single?.driver?.lastName
                  }
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {single?.driver?.firstName}
                </Tooltip>
              </a>
              <Tooltip
                title="Copy Phone Number"
                style={{
                  cursor: 'pointer',
                }}
              >
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(single?.driver?.phone);
                    message.success('Copied to clipboard');
                  }}
                  shape="circle"
                  style={{
                    background: 'black',
                    borderColor: 'black',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<PhoneFilled size="8" />}
                />
              </Tooltip>
            </Space>
            <span
              style={{
                whiteSpace: 'nowrap',
                fontWeight: '500',
              }}
            >
              {currentShift.start} - {currentShift.end}
            </span>
          </div>
        </div>
        <RenderMiniCurrentStatus status={driverStatus} align="right" />
      </Col>
    ) : null;
  };

  const RenderMerchantInfo = ({ single }) => {
    const merchantStatus = getLastCurrentStatus(single, 'merchant');
    return (
      <div>
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}
        >
          <Image
            src={single?.merchant?.merchant_logo}
            height={48}
            width={48}
            style={{
              borderRadius: '100%',
              objectFit: 'contain',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Space>
              <a
                style={{
                  color: '#A72079',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(single?.merchant?.merchant_id);
                  message.success('Copied to clipboard');
                }}
              >
                #M({single?.merchant?.merchant_id})
              </a>
              <Tooltip
                title="Copy Phone Number"
                style={{
                  cursor: 'pointer',
                }}
              >
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(
                      single?.merchant?.merchant_phone,
                    );
                    message.success('Copied to clipboard');
                  }}
                  shape="circle"
                  style={{
                    background: 'black',
                    borderColor: 'black',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<PhoneFilled size="8" />}
                />
              </Tooltip>
            </Space>
            <span
              style={{
                whiteSpace: 'nowrap',
                fontWeight: '500',
              }}
            >
              {single?.merchant?.merchant_name?.en}
            </span>
          </div>
        </div>
        <RenderMiniCurrentStatus status={merchantStatus} align="left" />
      </div>
    );
  };
  const MerchantDriverInfo = ({ single, isShow, isOrderHistory }) => {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: isShow ? 'flex' : 'none',
            flexDirection: 'column',
            gap: '10px 0',
          }}
        >
          <Row
            justify="space-between"
            style={{
              width: '100%',
            }}
          >
            <Col span={12}>
              <RenderMerchantInfo key={single?.orderId} single={single} />
            </Col>
            <RenderDriverInfo key={single?.driver?._id} single={single} />
          </Row>
          <hr style={{ width: '100%' }} />
          <Space
            style={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'left',
              display: 'flex',
            }}
          >
            {(() => {
              const statusTimeline = statusTiming(single);

              return statusTimeline
                ?.filter((a) => true || a.type == 'timeline')
                .map((st, index) => {
                  let time = moment(st.timestampData).format(format);
                  let checkLate = st.duration;
                  let color = st?.textColor;
                  let bgColor = getColor(0, st.threshold, checkLate);
                  let title = st?.title;

                  return statusData(
                    title,
                    time,
                    checkLate,
                    bgColor,
                    color,
                    index,
                  );
                });
            })()}
          </Space>
        </div>
        <Col
          span={24}
          style={{ margin: '10px 0', display: isShow ? 'flex' : 'none' }}
        >
          <OrderChips single={single} />
        </Col>
        {isOrderHistory ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() =>
              setShowCollapse((prev) => {
                if (prev) {
                  return null;
                }
                return single?.orderId;
              })
            }
          >
            {isShow ? <UpOutlined size={20} /> : <DownOutlined size={20} />}
          </div>
        ) : null}
      </Space>
    );
  };

  const getDriverCurrentShift = (single) => {
    const shift = single?.driver?.shift || [];
    const currentDay = {
      start: 0,
      end: 0,
    };
    const today = moment();
    for (let i = 0; i < shift.length; i++) {
      const element = shift[i];
      if (element.day == today.format('dddd')) {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        const currentTimeInMinutes = currentHour * 60 + currentMinute;

        if (
          currentTimeInMinutes > element.time?.[0].from &&
          currentTimeInMinutes < element.time?.[0].to
        ) {
          const start = moment()
            .startOf('day')
            .add(element.time?.[0].from, 'minutes')
            .format('HH:mm');
          const end = moment()
            .startOf('day')
            .add(element.time?.[0].to, 'minutes')
            .format('HH:mm');
          currentDay.start = start;
          currentDay.end = end;
        }
      }
    }
    return currentDay;
  };

  const SingleCard = ({ single, isShow, isOrderHistory }) => {
    const countDownDate = moment(single?.orderEstReadyAt);
    const checkCountDown = moment().diff(countDownDate, 'minutes');
    return (
      <Card
        title={
          <Space
            style={{
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                margin: 0,
              }}
            >
              <Tooltip
                title={
                  <Button
                    type="primary"
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/order-details?oid=${single?.orderId}`,
                        '_blank',
                        'noopener,noreferrer',
                      )
                    }
                  >
                    Show Order Detail
                  </Button>
                }
              >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(single?.orderId);
                    message.success('Copied to clipboard');
                  }}
                >
                  <span
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      fontSize: 18,
                      color: '#3274d1',
                    }}
                  >
                    #{single?.orderId}
                  </span>
                </a>
              </Tooltip>
            </p>
            <p
              style={{
                fontSize: 15,
                fontWeight: '200',
                margin: 0,
              }}
            >
              <ClockCircleOutlined />
              {` ${
                moment(single?.createdAt).format('YYYY-MM-DD HH:mm:ss') || 0
              } `}
            </p>
          </Space>
        }
        extra={
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            direction="vertical"
          >
            {checkStatus(single)}
          </div>
        }
        style={{ width: '100%', margin: '15px 0' }}
      >
        <Row gutter={[0, 0]} justify="space-between">
          <Col span={6}>
            <Space
              direction="vertical"
              style={{
                lineHeight: 1.1,
              }}
            >
              {single?.orderPaymentTypeId !== 0 &&
              single?.orderPaymentTypeId !== 2 &&
              !isOrderHistory ? (
                <Popconfirm
                  title="Do you want to convert payment to cash?"
                  onConfirm={() => convertPayment(single?.orderId)}
                >
                  <p
                    style={{
                      cursor: 'pointer',
                      fontSize: 15,
                      color: single?.orderPaymentId ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {showOrderPaymentType(single?.orderPaymentTypeId)}
                  </p>
                </Popconfirm>
              ) : (
                <p
                  style={{
                    fontSize: 15,
                    color: single?.orderPaymentId ? 'green' : 'red',
                    fontWeight: 'bold',
                  }}
                >
                  {showOrderPaymentType(single?.orderPaymentTypeId)}
                </p>
              )}

              <span
                style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                {single?.orderTotal?.toLocaleString()} IQD
              </span>
            </Space>
          </Col>
          <Col span={3}>
            <Space
              direction="vertical"
              style={{
                lineHeight: 1.1,
              }}
            >
              <p>Address</p>
              <span
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  fontSize: 18,
                  color: '#3274d1',
                }}
              >
                {single?.orderCity}
              </span>
            </Space>
          </Col>
          <Col offset={3} span={4}>
            {single?.orderStatus == 'completed' ||
            single?.orderStatus == 'canceled' ? null : (
              <Space
                direction="vertical"
                style={{
                  lineHeight: 1.1,
                }}
              >
                <p>{countDownDate.format(format)}</p>
                <span
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    padding: '5px 7px',
                    borderRadius: 50,
                    backgroundColor:
                      checkCountDown < 0
                        ? 'red'
                        : checkCountDown > 5
                        ? 'orange'
                        : 'grey',
                    color: 'white',
                  }}
                >
                  {checkCountDown}
                  Mins
                </span>
              </Space>
            )}
          </Col>
          <Col
            span={7}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                gap: '5px',
                width: 120,
              }}
            >
              <Tooltip title="Change Status">
                <Button
                  onClick={() => openOrderStatusChangeModal(single)}
                  type="primary"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilClipboardNotes size="22" />}
                />
              </Tooltip>
              <Tooltip title="Map View">
                <Button
                  onClick={() => openOrderMapModal(single)}
                  style={{
                    background: '#e67e22',
                    borderColor: '#e67e22',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilMap size="22" />}
                />
              </Tooltip>
              <Tooltip title="Compensate/Refund">
                <Button
                  onClick={() => openOrderRefundModal(single)}
                  style={{
                    background: '#8e44ad',
                    borderColor: '#8e44ad',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilUsdCircle size="22" />}
                />
              </Tooltip>
              <Tooltip title="Order Cart">
                <Button
                  onClick={() => openOrderCartModal(single)}
                  style={{
                    background: '#16a085',
                    borderColor: '#16a085',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilShoppingCartAlt size="22" />}
                />
              </Tooltip>
              <Tooltip title="Edit Order">
                <Button
                  onClick={() => openEditOrderModal(single)}
                  style={{
                    background: '#c0392b',
                    borderColor: '#c0392b',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  icon={<UilEdit size="22" />}
                />
              </Tooltip>
            </div>
          </Col>

          <Col span={24}>
            <hr style={{ margin: '10px 0' }} />
          </Col>
          <Col span={24}>
            {
              <MerchantDriverInfo
                single={single}
                isShow={isShow}
                isOrderHistory={isOrderHistory}
              />
            }
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div
      // className="new-chat-container"
      style={{}}
    >
      {SingleCard({ single, isShow: true, isOrderHistory: false })}

      {changeOrderStatusModalVisible && (
        <ChangeOrderStatusModalComponent
          status={changeOrderStatusModalVisible}
          setStatus={setChangeOrderStatusModalVisible}
          selectedOrder={selectedOrder}
          getAllOrders={() => {}}
        />
      )}
      {orderMapModalVisible && (
        <OrderMapModalComponent
          status={orderMapModalVisible}
          setStatus={setOrderMapModalVisible}
          getAllOrders={() => {}}
          selectedOrder={selectedOrder}
        />
      )}
      {orderRefundModalVisible && (
        <OrderRefundModal
          status={orderRefundModalVisible}
          setStatus={setOrderRefundModalVisible}
          selectedOrder={selectedOrder}
        />
      )}
      {orderCartModalVisible && (
        <OrderCartModalComponent
          status={orderCartModalVisible}
          setStatus={setOrderCartModalVisible}
          selectedOrder={selectedOrder}
          getAllOrders={() => {}}
        />
      )}
      {editOrderModalVisible && (
        <EditOrderModal
          status={editOrderModalVisible}
          setStatus={setEditOrderModalVisible}
          selectedOrder={selectedOrder}
          getAllOrders={() => {}}
        />
      )}
      {showCustomerInfoModal ? (
        <CustomerInformationModal
          status={showCustomerInfoModal}
          setStatus={setShowCustomerInfoModal}
          selectedOrder={{ selectedOrder }}
        />
      ) : null}

      {showVipModal ? (
        <CustomerVIPModal
          open={showVipModal}
          setOpen={setShowModalVip}
          customer={customer}
        />
      ) : null}
    </div>
  );
}

export default SingleOrderCard;

import React, { useReducer } from 'react';
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import {
  useAxiosGet,
  useAxiosPost,
  useAxiosPostTaskManagement,
} from '../../configs/axios';
import useLocalStorage from '../../configs/localStorage';
import CreateTicketComponent from './CreateTicketComponent';

const initialState = {
  open: false,
  workingStatus: null,
  closeType: null,
};

const reducer = (state, action) => ({
  ...state,
  ...action,
});

const MerchantInformationModal = (props) => {
  const [form] = Form.useForm();
  const { status, setStatus, selectedOrder } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const [auth] = useLocalStorage('auth', null);
  const canMerchantClosure = auth?.user?.canMerchantClosure;

  const { request: closeMerchantRequestP, loading: merchantCloseLoading, error: closeMerchantError } =
    useAxiosPost('/merchants/temporarilyCloseMerchant');

  const {
    request: createClosureTaskRequest,
    loading: createClosureTaskLoading,
  } = useAxiosPostTaskManagement('/lezzooBoard/createClosure', {});

  const { data: reasons, loading: reasonLoading } = useAxiosGet(
    '/merchants/closeReasons',
    { autoRun: true },
  );

  const { data: dataAccountManager } = useAxiosGet(
    '/merchants/getAccountManager',
    {
      merchantId: selectedOrder?.merchant?.merchant_id,
      autoRun: true,
    },
  );

  const onDrawerClose = () => dispatch({ open: false });
  const onDrawerOpen = () => dispatch({ open: true });

  const onWorkingStatusChange = (value) => {
    dispatch({ workingStatus: value, closeType: null });
    form.setFieldsValue({ closeType: null });
  };

  const onCloseTypeChange = (value) => dispatch({ closeType: value });

  const onClose = () => {
    
    setStatus(false);
  };
  const onFormSubmit = async (values) => {
    const formData = {
      merchantId: selectedOrder.merchant.merchant_id,
      workingStatus: values.workingStatus,
      closingType: values.closeType,
      reason: values.reason,
      merchantName: selectedOrder?.merchant?.merchant_name?.en || 'Unknown Name',
    };  


    // console.log(selectedOrder.merchant.merchant_name.en, 'this is the merchant id');
    // First, attempt to close the merchant. If it fails, log the error but continue with the task creation.
    try {
      await closeMerchantRequestP(formData);
    } catch (error) {
      // Log and display the error for the failed merchant closure request
      const errorMessage =
        error?.response?.data?.msg || error?.message || 'Unknown error occurred';
      const statusCode = error?.response?.status || 'Unknown status code';
      message.error(
        `Failed to close the merchant: ${errorMessage}. Status code: ${statusCode}.`,
      );
    }
  
    // Regardless of whether the merchant closure succeeds or fails, proceed to create the closure task.
    try {
      // Call createClosureTaskRequest API to create closure task
      await createClosureTaskRequest({
        merchantId: selectedOrder.merchant.merchant_id,
        workingStatus: values.workingStatus,
        closingType: values.closeType,
        reason: values.reason,
      });
  
      // Dispatch to close modal and reset form
      form.resetFields();
      message.success(
        `Merchant closure task for "${selectedOrder.merchant.merchant_name}" has been successfully submitted.`,
      );
  
    } catch (err) {
      // Log the error if task creation fails
      console.error('Failed to create closure task:', err);
      message.error(
        `Failed to create closure task: ${err?.message || 'Unknown error'}. Please check your connection or access rights and try again later.`,
      );
    } finally {
      // Close the drawer after the form is submitted, regardless of success or failure
      onClose();
      dispatch({ open: false });
    }
  };
  
 

  

  return (
    <>
      <Modal
        title="Merchant Information"
        destroyOnClose
        open={status}
        footer={null}
        onCancel={() => setStatus(false)}


        centered
        width={600}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Avatar
            size={90}
            src={<Image src={selectedOrder.merchant.merchant_logo} />}
          />
          <h2 style={{ textAlign: 'center' }}>
            {selectedOrder.merchant.merchant_name.en}
            <small
              style={{
                fontSize: '13px',
                color: 'gray',
                marginLeft: '10px',
              }}
            >
              ({selectedOrder.merchant.merchant_id})
            </small>
          </h2>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <b>Phone Number</b>
              <p
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(
                    selectedOrder.merchant.merchant_phone,
                  );
                  message.success('Copied to clipboard');
                }}
              >
                {selectedOrder.merchant.merchant_phone}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <b>City</b>
              <p>{selectedOrder.merchant.merchant_city}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <b>Order Manager</b>
              <p>{dataAccountManager?.email}</p>
            </div>
          </div>

          <CreateTicketComponent
            issue_source_type={'order'}
            issue_source_id={selectedOrder.orderId}
            issue_type="merchant"
          />

          {canMerchantClosure && (
            <Button onClick={onDrawerOpen} type="primary" block>
              Close merchant
            </Button>
          )}
        </div>
      </Modal>
      <Drawer
        title="Close Merchant"
        placement="right"
        width={500}
        closable
        onClose={onDrawerClose}
        open={state.open}
      >
        <Form
          onFinish={onFormSubmit}
          size="large"
          form={form}
          layout="vertical"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="workingStatus"
                label="Working Status"
                rules={[
                  { required: true, message: 'Please select working status' },
                ]}
              >
                <Select
                  placeholder="Select working status"
                  onChange={onWorkingStatusChange}
                >
                  <Select.Option value="busy">Busy</Select.Option>
                  <Select.Option value="closed">Closed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="closeType"
                label="Close Type"
                rules={[
                  { required: true, message: 'Please select close type' },
                ]}
              >
                <Select
                  placeholder="Select close type"
                  onChange={onCloseTypeChange}
                >
                  <Select.Option value="temporary">Temporary</Select.Option>
                  {state.workingStatus === 'closed' && (
                    <Select.Option value="permanent">Permanent</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[{ required: true, message: 'Please select a reason' }]}
              >
                <Select placeholder="Select a reason" loading={reasonLoading}>
                  {reasons?.map((reason) => (
                    <Select.Option
                      key={reason.reason_title}
                      value={reason.reason_title}
                    >
                      {reason.reason_title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Button
            loading={merchantCloseLoading}
            htmlType="submit"
            type="primary"
            block
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default MerchantInformationModal;

import { Button, Col, Form, Modal, Row, Select, Tooltip, message } from 'antd';
import styles from './styles/AssignAgentModal.module.css';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { useAxiosPostChat } from '../../configs/axios';
import { RiEBike2Line } from 'react-icons/ri';
import { RiStore3Line } from 'react-icons/ri';
import { MdSupportAgent } from 'react-icons/md';

const AssignAgentModal = ({
  selectedChatroom,
  chatSource,
  showModal,
  setShowModal,
  hideButton,
}) => {
  return (
    <div>
      {hideButton ? null : (
        <button
          className={styles.AssignAgentButton}
          onClick={() => setShowModal(true)}
          style={{
            cursor: 'pointer',
          }}
        >
          <img src="/svg/agentIcon.svg" className={styles.agentIconStyle} />
        </button>
      )}
      {showModal ? (
        <AgentModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedChatroom={selectedChatroom}
          chatSource={chatSource}
        />
      ) : null}
    </div>
  );
};

const AgentModal = ({
  showModal,
  setShowModal,
  selectedChatroom,
  chatSource,
}) => {
  const { request: requestAssignChat, loading: loadingAssignChat } =
    useAxiosPostChat('/agents/assign', {
      autoRun: true,
    });

    const [selectedAgentType, setSelectedAgentType] = useState();
  const onFinish = async (values) => {
    if (!selectedAgentType) {
      message.error('Select a team')
      return;
    }
    try {
      const body = {
        roomTeam: selectedAgentType,
        comment: values.comment,
        roomId: selectedChatroom._id,
        chatSource: selectedChatroom.roomType,
      };

      await requestAssignChat(body);
      setShowModal(false);
      message.success('Value assigned successfully');
    } catch (e) {
      message.error('Something went wrong');
    }
  };
  return (
    <Modal
      title="Select a Team"
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      <Form onFinish={onFinish}>
        <Row gutter={[10, 10]} justify={'space-around'}>
          {/* Dropdown for fixed values */}
          <Col>
            <Tooltip title="Driver Support">
              <Button
                onClick={() => setSelectedAgentType('DS')}
                danger
                type={selectedAgentType == 'DS' ? 'primary' : ''}
                icon={<RiEBike2Line />}
              >
                DS
              </Button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Merchant Support">
              <Button
                onClick={() => setSelectedAgentType('MS')}
                style={{
                  backgroundColor: selectedAgentType == 'MS' ? 'green' : '',
                  borderColor: selectedAgentType != 'MS' ? 'green' : '',
                  color: selectedAgentType != 'MS' ? 'green' : 'white',
                }}
                icon={<RiStore3Line />}
              >
                MS
              </Button>
            </Tooltip>
          </Col>
          <Col>
            {' '}
            <Tooltip title="Customer Support">
              <Button
                onClick={() => setSelectedAgentType('CA')}
                style={{
                  backgroundColor: selectedAgentType == 'CA' ? '#1677ff' : '',
                  borderColor: selectedAgentType != 'CA' ? '#1677ff' : '',
                  color: selectedAgentType != 'CA' ? '#1677ff' : 'white',
                }}
                icon={<MdSupportAgent />}
              >
                CA
              </Button>
            </Tooltip>
          </Col>
  

          {/* Comment field */}
          <Col span={24}>
            <Form.Item name={'comment'}>
              <TextArea className={styles.textAreaStyle} />
            </Form.Item>
          </Col>

          {/* Footer buttons */}
          <Col>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Col>
          <Col>
            <Button
              className={styles.submitButton}
              htmlType="submit"
              loading={loadingAssignChat}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AssignAgentModal;

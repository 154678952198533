import { useReducer, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  message,
  Tag,
  DatePicker,
  Input,
  Typography,
} from 'antd';
import RemoteSelectForm from '../utility/RemoteSelectForm';
import {
  useAxiosGetTaskManagement,
  useAxiosPostTaskManagement,
} from '../../configs/axios';
import classes from '../TaskBoard/style/task-board-drawer.module.css';
import CustomMention from '../TaskBoard/CustomMention';
import FileUploader from '../utility/FileUploader';
import RemoteSelect from '../utility/RemoteSelect';
const { Text, Title } = Typography;

const CreateTicketComponentButton = () => {
  const [form] = Form.useForm();
  const issueSource = Form.useWatch('issue_source', form);
  const department = Form.useWatch('department', form);
  const { request: requestCreateTicket, loading: loadingCreateTicket } =
    useAxiosPostTaskManagement('/lezzooBoard/AddTask', {
      autoRun: false,
    });

  const initValue = {
    showCreateTicketModal: false,
  };

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );

  const commentInput = useRef();
  const selectedAgent = useRef([]);

  const { data: requestBoardData, request: requestBoardFields } =
    useAxiosGetTaskManagement('/lezzooBoard/getBoardFields', {
      autoRun: false,
    });

  const onFinishTicket = async (values) => {
    try {
      const newBody = {
        issue_source: values.issue_source || [],
        task_description: commentInput.current || null,
        userNames: selectedAgent.current?.map((agent) => agent.value) || [],
        issue_id: values.issue_id.value,
        issue_name: values.issue_id.label || null,
        task_name: values.task_name || null,
        files:
          values.file?.map((image) => ({
            url: image.url,
            type: image.type,
            name: image.name,
          })) || [],
        issue_on: values.issue_on || null,
        priority: values.priority || null,
        product: values.product || null,
        city: values.city?.value || null,
        estimatedTime: values.estimatedTime
          ? values.estimatedTime.format('YYYY-MM-DD HH:mm:ss')
          : null,
        assign_to: values.assign_to ? values.assign_to.value : null,
        assign_to_name: values.assign_to ? values.assign_to.label : null,
        automate_id: values.task_schedule?.value || null,
        order_id: values.order_id || null,
        fields: requestBoardData?.map((field) => ({
          label: field.name,
          value: values[field.name] || null,
        })),
      };

      commentInput.current = '';
      values?.issue_source?.forEach((source) => {
        newBody[source] = values[source]?.value;
      });

      await requestCreateTicket(newBody);
      message.success('Operation successful');
      form.resetFields();
      setState({
        showCreateTicketModal: false,
      });
    } catch (error) {
      console.error('Error:', error);
      message.error('Something went wrong');
    }
  };

  const onShowModal = () =>
    setState({
      showCreateTicketModal: true,
    });

  const onCloseModal = () => {
    form.resetFields();
    setState({
      showCreateTicketModal: false,
    });
  };

  const handleDepartmentChange = async ({ key: departmentId }) => {
    try {
      await requestBoardFields({ department_id: departmentId });
    } catch (error) {
      console.error('Error fetching board fields:', error);
    }
  };

  const showValueOption = {
    merchant: 'merchant_id',
    driver: 'courier_external_id',
    customer: 'customer_id',
    agent: '_id',
  };
  const showUrlOption = {
    merchant: '/merchants/',
    driver: '/couriers/list',
    customer: '/customers/',
    agent: '/users/status',
  };

  const createLabel = {
    merchant: 'Merchant',
    driver: 'Driver',
    customer: 'Customer',
    agent: 'Agent',
  };

  const priorityOptions = [
    { label: 'High', value: 'High', color: '#E63946' },
    { label: 'Medium', value: 'Medium', color: '#F4A261' },
    { label: 'Low', value: 'Low', color: '#00BD9D' },
  ];

  const showLabelOption = (source, record) => {
    switch (source) {
      case 'merchant':
        return `${record.merchant_id} - ${record.merchant_name} - ${record.merchant_city}`;
      case 'driver':
        return `${record.courier_id} - ${record.courier_name} - ${record.courier_city}`;
      case 'customer':
        return `${record.customer_id} - ${record.customer_name} - ${record.customer_city}`;
      case 'agent':
        return `${record.displayname_en}`;
      default:
        return 'value';
    }
  };

  return (
    <>
      <Button
        size="large"
        type="primary"
        style={{
          borderRadius: '60px',
        }}
        onClick={onShowModal}
      >
        Create A Ticket
      </Button>
      <Modal
        title="Create Ticket"
        footer={null}
        open={state.showCreateTicketModal}
        onCancel={onCloseModal}
        width={1200}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          style={{
            width: '100%',
          }}
          onFinish={onFinishTicket}
        >
          <Row gutter={[12, 4]}>
            <Col span={8}>
              <Form.Item name="task_name" label="Task Name">
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <RemoteSelectForm
                labelOption="department_name"
                optionText="department_name"
                valueOption={'department_id'}
                placeholder="department"
                nameForm={'department'}
                allowClear={true}
                filterFromServer={false}
                labelForm={'Department'}
                endpoint="/lezzooBoard/departments"
                fetchAxios={useAxiosGetTaskManagement}
                onValueChange={handleDepartmentChange}
              />
            </Col>

            <Col span={6}>
              <RemoteSelectForm
                endpoint={'/OperationTasks/issueList'}
                labelForm="Issue"
                rules={[
                  {
                    required: true,
                    message: 'Please select an issue',
                  },
                ]}
                style={{ width: '100%' }}
                valueOption="task_issue_id"
                labelOption="task_issue_name"
                nameForm="issue_id"
                allowClear={true}
                query={{
                  department_id: department?.value,
                }}
                dependency={[department?.value]}
                placeholder="Select an issue"
                fetchAxios={useAxiosGetTaskManagement}
                disabled={!department}
              />
            </Col>

            <Col span={6}>
              <Form.Item name="issue_source" label="Issue with">
                <Select
                  mode="multiple"
                  onChange={(selectedValues) => {
                    if (!selectedValues.includes('order')) {
                      form.setFieldsValue({ order_id: '' });
                    }
                  }}
                  options={[
                    { value: 'driver', label: 'Driver' },
                    { value: 'merchant', label: 'Merchant' },
                    { value: 'customer', label: 'Customer' },
                    { value: 'agent', label: 'Agent' },
                    { value: 'order', label: 'Order' },
                  ]}
                  style={{ width: '100%' }}
                  disabled={!department}
                />
              </Form.Item>
            </Col>
            {form.getFieldValue('issue_source')?.includes('order') && (
              <Col span={6}>
                <Form.Item
                  name="order_id"
                  label="Order ID"
                  rules={[
                    {
                      required: form
                        .getFieldValue('issue_source')
                        ?.includes('order'),
                      message: 'Please enter the Order ID',
                    },
                  ]}
                >
                  <Input placeholder="Enter Order ID" />
                </Form.Item>
              </Col>
            )}

            <Col span={6}>
              <Form.Item name="issue_on" label="Issue On">
                <Select
                  options={issueSource?.map((source) => ({
                    value: source,
                    label: source,
                  }))}
                  style={{ width: '100%' }}
                  disabled={!department}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <RemoteSelectForm
                endpoint={'/lezzooBoard/getListAutomateTask'}
                labelForm="Task Schedules To Attach"
                style={{ width: '100%' }}
                valueOption="automate_name"
                labelOption="schedule_name"
                nameForm="task_schedule"
                allowClear={true}
                filterFromServer
                dependency={[department?.value]}
                placeholder="Select an issue"
                fetchAxios={useAxiosGetTaskManagement}
                disabled={!department}
              />
            </Col>

            {issueSource?.length > 0 &&
              issueSource.map(
                (source) =>
                  source !== 'order' && (
                    <Col span={8} key={source}>
                      <RemoteSelectForm
                        labelForm={createLabel[source]}
                        endpoint={showUrlOption[source]}
                        rules={[
                          {
                            required: true,
                            message: `Please select a ${createLabel[source]}`,
                          },
                        ]}
                        style={{ width: '100%' }}
                        valueOption={showValueOption[source]}
                        optionText={(record) => showLabelOption(source, record)}
                        nameForm={source}
                        allowClear={true}
                        filterFromServer={true}
                        placeholder={`Select a ${createLabel[source]}`}
                        dependency={[source]}
                        query={{ limit: 10 }}
                      />
                    </Col>
                  ),
              )}

            <Col span={24}>
              <Form.Item
                name="comment"
                label='Type your comment Or mention someone by typing "@"'
                style={{ marginBottom: 100 }}
              >
                <div className={classes.commentInputContainer}>
                  <CustomMention
                    comment={commentInput}
                    selectedUser={selectedAgent}
                  />
                </div>
              </Form.Item>
            </Col>

            <Col span={8}>
              <RemoteSelectForm
                labelForm={'Assign To'}
                nameForm={'assign_to'}
                labelOption={'userName'}
                valueOption={'_id'}
                allowClear={true}
                placeholder="Select Agent"
                filterFromServer={true}
                endpoint={'/users/getUserForMention'}
                query={{
                  limit: 10,
                }}
              />
            </Col>

            <Col span={8}>
              <Form.Item name="priority" label="Priority">
                <Select
                  placeholder="Select priority"
                  options={priorityOptions?.map((priority) => ({
                    key: priority.value,
                    value: priority.value,
                    label: priority.label,
                  }))}
                ></Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <RemoteSelectForm
                nameForm={'city'}
                labelForm={'City'}
                endpoint={'/cities/'}
                labelOption={'city_code'}
                valueOption={'city_code'}
                rules={[
                  {
                    required:
                      requestBoardData?.find((field) => field.name === 'city')
                        ?.required === 1,
                    message: 'Please select city',
                  },
                ]}
                style={{ margin: 0 }}
              />
            </Col>
            <Col span={8}>
              <Form.Item name="estimatedTime" label="Estimated Time">
                <DatePicker
                  placeholder="Select estimated time"
                  format="YYYY-MM-DD HH:mm"
                  showTime={{ format: 'HH:mm' }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            {requestBoardData?.map(
              (field) =>
                // Render other fields normally, excluding 'city' from the placeholders if it exists
                field.name !== 'city' && (
                  <Col span={8} key={field.board_field_id}>
                    <Form.Item
                      name={field.name}
                      label={
                        field.name.charAt(0).toUpperCase() + field.name.slice(1)
                      }
                      rules={[
                        {
                          required: field.required === 1,
                          message: `Please select ${field.name}`,
                        },
                      ]}
                    >
                      {field.type === 'select' ? (
                        <Select placeholder={`Select ${field.name}`}>
                          {field.values.map((option) => (
                            <Option key={option.value} value={option.value}>
                              <Tag
                                color={option.color}
                                style={{ borderRadius: '8px' }}
                              >
                                {option.value}
                              </Tag>
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Input placeholder={`Enter ${field.name}`} />
                      )}
                    </Form.Item>
                  </Col>
                ),
            )}
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="file" label="Files">
                <FileUploader
                  form={form}
                  formName="file"
                  numberOfUpload={10}
                  imageUploader
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreateTicket}
              style={{
                borderRadius: '8px',
                width: '100%',
                backgroundColor: '#FF4D4F',
                color: '#FFF',
              }}
            >
              Create Ticket
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTicketComponentButton;

import { CloseOutlined, UserSwitchOutlined } from '@ant-design/icons';
import {
  Image,
  Spin,
  Tooltip,
  Upload,
  message as alertMessage,
  Skeleton,
  Button,
  notification,
  Tag,
} from 'antd';
import moment from 'moment/moment';
import useLocalStorage from './../../configs/localStorage';
import styles from './styles/chat-message.module.css';
import { useEffect, useRef, useState } from 'react';
import {
  useAxiosGetChat,
  useAxiosPost,
  useAxiosPostChat,
  useAxiosPut,
} from '../../configs/axios';
import {
  UilCheckCircle,
  UilMessage,
  UilPaperclip,
  // DollarOutlined,
} from '@iconscout/react-unicons';
import InfiniteScroll from 'react-infinite-scroll-component';

import dayjs from 'dayjs';
import ChatMap from './ChatMap';
import { getSocket } from '../../store/socket/socketGetter';
import MakeGroupOrderModal from './ReAssignChatModal';
import BookmarkChat from './Bookmark';
import { darkModeLocalConst } from '../../configs/constants';
const typingMessage = require('./../../assets/icons/typing_animation.gif');
function ChatMessageTabs({
  selectedChatroom,
  setSelectedChatroom,
  isDrawer,
  disableActions,
  chatrooms,
  setChatroomData,
  source,
  message,
  setMessage,
  chatroomMessagesLoading,
  messages,
  setMessages,

  onLoadMoreMessages,
  canNotLoadMore,
}) {
  const { request: resolveChatroom } = useAxiosPostChat('/chats/resolveChat');
  const { request: uploadImage } = useAxiosPostChat('/chats/image');
  const [isTyping, setIsTyping] = useState(false);
  const [auth] = useLocalStorage('auth', null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [colorLocalStorage] = useLocalStorage(
    darkModeLocalConst,
    null
  );

  const [socket, setSocket] = useState(getSocket());

  const { request: requestSolveIssue } = useAxiosPost('/orders/solve_issue', {
    autoRun: false,
  });
  const [openReAssignChatModal, setOpenReAssignChatModal] = useState(false);

  const {
    request: requestForChangeLocation,
    loading: loadingForChangeLocation,
  } = useAxiosPut('/orders/status');
  const sendIsTyping = (isTyping) => {
    const selectedTab = source;
    const messageRoomName = `${selectedChatroom?.roomType}:${selectedChatroom?.roomRef}`;
    socket?.emit('typing', {
      text: isTyping,
      room: messageRoomName,
      userType: 'agent',
    });
  };

  // const { loading: onlineUserLoading, request: onlineUserRequest } =
  //   useAxiosGetChat('/chats/rooms/clients', {
  //     autoRun: false,
  //   });

  // useEffect(() => {
  //   if (!selectedChatroom?.roomRef) return;
  //   const roomName = `${selectedChatroom.roomType}:${selectedChatroom?.roomRef}`;
  //   // onlineUserRequest({ room: roomName }).then((res) => {
  //   //   setOnlineUsers(res);
  //   // });
  // }, [selectedChatroom?.roomRef]);

  /// create a ref to store the timer
  const turnOffTyping = useRef();

  useEffect(() => {
    if (socket) {
      socket?.on('room-typing', (data) => {
        if (!isTyping) {
          setIsTyping({
            text: data?.text,
            userType: data?.userType || 'customer',
          });
        }
        // clear the timer if it has already been set.
        clearTimeout(turnOffTyping.current);
        // set the timer to turn off typing after 2 seconds
        turnOffTyping.current = setTimeout(() => {
          setIsTyping(false);
        }, 2000);
      });
    }
  }, [socket]);

  const onResolveChatroom = async () => {
    try {
      if (!selectedChatroom) {
        return true;
      }
      await resolveChatroom({
        roomType: selectedChatroom.roomType,
        roomRef: selectedChatroom.roomRef,
        chatSource: selectedChatroom.roomType,
      });
      setSelectedChatroom({ ...selectedChatroom, status: 'closed' });
      if (!isDrawer && chatrooms && chatrooms.length > 0) {
        const newChatrooms = chatrooms.filter(
          (chatroom) => chatroom._id !== selectedChatroom._id,
        );
        setChatroomData(newChatrooms);
      }
      if (!isDrawer) {
        setSelectedChatroom(null);
      }

      // const body = {
      //   orderId: selectedChatroom.roomRef,
      //   issueCode: selectedChatroom.roomType,
      // };
      // await requestSolveIssue(body);
      alertMessage.success('Chatroom resolved successfully');
    } catch (error) {
      console.log(error);
      alertMessage.error('Error resolving chatroom');
    }
  };

  const { request: sendMessageRequest, loading: sendMessageIsLoading } =
    useAxiosPostChat('/chats');
  const sendMessage = async (message, messageType = 'text') => {

    if (!selectedChatroom || message.length == 0 || sendMessageIsLoading) {
      return;
    }
    const newMessage = {
      _id: Math.random() * 100000000000000,
      createdByName: auth.user.userName,
      createdById: auth.user._id,
      createdByAvatar: '',
      chatType: messageType,
      chatMessage: message,
      chatRoomId: selectedChatroom._id,
      lastMessage: '',
      messageSource: 'agent',
      isDeleted: false,
      createdAt: moment().format(),
      loading: true,
      __v: 0,
    };
    try {

      const requestBody = {
        message,
        roomRef: selectedChatroom.roomRef,
        messageType,
        roomId: selectedChatroom._id,
        chatSource: selectedChatroom.roomType,
        messageSource: 'agent',
      };
      setMessage('');

      setMessages((prev) => [newMessage, ...prev]);

      const respond = await sendMessageRequest(requestBody);

      document.getElementById('messageBox').value = '';
      setTimeout(() => {
        try {
          const chat = document.getElementById('scrollableDiv');
          if (chat) {
            chat.scrollTop = chat.scrollHeight;
          }
        } catch (e) {
        }
      }, 100);

      /////
      if (!isDrawer && chatrooms && chatrooms.length > 0) {
        // put the chatroom to the top of the list
        const newChatroomData = chatrooms?.filter(
          (chatroom) => chatroom._id !== selectedChatroom._id,
        );
        selectedChatroom.lastMessage = message;
        newChatroomData.countMessages = selectedChatroom?.countMessages || 0;
        newChatroomData?.unshift(selectedChatroom);
        setChatroomData(newChatroomData);
      }
      if (selectedChatroom.status != 'opened') {
        setSelectedChatroom({ ...selectedChatroom, status: 'opened' });
      }
      newMessage.loading = false;
    } catch (error) {
      newMessage.loading = false;
      newMessage.error = true;
    }
  };

  // Function to render missing items
  const RenderMissingItems = ({ missingItems }) => {
    const arr = Array.isArray(missingItems?.flowPayload)
      ? missingItems.flowPayload
      : [];

    return (
      <div className={styles['missing-items-container']}>
        {/* card header */}
        <div className={styles['card-header']}>
          <div>
            <h3>{missingItems?.messageOptionId?.replace(/_/g, ' ')}</h3>
            {/* <p>
              Product ID: <span>{missingItems?.flowPayload?.product_id}</span>
            </p> */}
          </div>
        </div>

        <div className={styles['cards']}>
          {arr?.map((item, index) => {
            return (
              <>
                {/* Card */}
                <div
                  className={styles['missing-items-card']}
                  key={index?.toString()}
                >
                  {/* left */}
                  <div className={styles['left-side']}>
                    <div className={styles['image-container']}>
                      <img
                        src={item?.product_image_url}
                        width={51}
                        height={51}
                      />
                      <p className={styles['quantity']}>{item?.quantity}x</p>
                    </div>
                    <div>
                      <h4>{item?.product_name_object['en']}</h4>

                      <ul className={styles['list']}>
                        <li>
                          Product ID: <span>{item?.product_id}</span>
                        </li>
                        <li>
                          Price:{' '}
                          <span>
                            {(item.single_product_price||item?.product_price)?.toLocaleString()}{' '}
                            <small>IQD</small>{' '}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };
  const resendMessage = async (newMessage) => {
    try {
      const requestBody = {
        message: newMessage.chatMessage,
        roomRef: selectedChatroom.roomRef,
        messageType: newMessage.chatType,
        roomId: selectedChatroom._id,
        chatSource: source,
        messageSource: 'agent',
      };

      newMessage.loading = true;
      newMessage.error = false;
      const respond = await sendMessageRequest(requestBody);

      // put the chatroom to the top of the list
      const newChatroomData = chatrooms?.filter(
        (chatroom) => chatroom._id !== selectedChatroom._id,
      );
      selectedChatroom.lastMessage = message;
      newChatroomData?.unshift(selectedChatroom);
      setChatroomData(newChatroomData);
      if (selectedChatroom.status != 'opened') {
        setSelectedChatroom({ ...selectedChatroom, status: 'opened' });
      }
      newMessage.loading = false;
    } catch (e) {
      newMessage.loading = false;
      newMessage.error = true;
    }
  };

  const renderTimeAgo = (date1, date2) => {
    if (date2 == undefined) {
      return null;
    }
    const time = dayjs(date1).format('YYYY-MM-DD').toString();
    const time2 = dayjs(date2).format('YYYY-MM-DD').toString();
    const con = time !== time2;
    if (con) {
      return (
        <div
          style={{
            opacity: '0.5',
            fontWeight: 'bold',
            fontSize: '12px',
            marginBottom: '5px',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 20,
            marginTop: 20,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: '1px',
              backgroundColor: '#4A5486',
              flex: 1,
              marginRight: 10,
            }}
          ></div>
          <span>
            {dayjs().diff(dayjs(time), 'day') == 0
              ? 'Today'
              : dayjs().diff(dayjs(time), 'day') == 1
              ? 'Yesterday'
              : dayjs(date1).format('DD MMM')}
          </span>
          <div
            style={{
              height: '1px',
              backgroundColor: '#4A5486',
              flex: 1,
              marginLeft: 10,
            }}
          ></div>
        </div>
      );
    }

    return null;
  };

  const returnImageSource = (chatSource, source) => {
    if (source && source != 'undefined') {
      if (chatSource === 'customer_general') {
        return `https://d2wcj0x98hsnim.cloudfront.net/${source}`;
      }
      return source;
    }
    if (chatSource == 'orders') {
      return 'https://cdn0.iconfinder.com/data/icons/e-commerce-200/256/shop-512.png';
    }
    if (chatSource == 'couriers') {
      return 'https://cdn-icons-png.flaticon.com/512/1170/1170459.png';
    }
    if (
      chatSource == 'customer_agent' ||
      chatSource == 'customer_general' ||
      chatSource == 'customer_order'
    ) {
      return 'https://conceptdraw.com/a3179c3/p20/preview/640/pict--customer,-man-sales-symbols-vector-stencils-library';
    }
    return 'https://cdn0.iconfinder.com/data/icons/e-commerce-200/256/shop-512.png';
  };

  /// false is left
  /// true is right
  const sides = {
    couriers: {
      canSendMessage: true,
      messageSource: {
        courier: false,
        'courier-system': false,
        'cs-agent': true,
        agent: true,
      },
    },
    orders: {
      canSendMessage: true,
      messageSource: { courier: false, 'cs-agent': true, agent: true },
    },
    'orders,couriers': {
      canSendMessage: true,
      messageSource: { courier: false, 'cs-agent': true, agent: true },
    },
    customer_agent: {
      canSendMessage: true,
      messageSource: { customer: false, 'cs-agent': true, agent: true },
    },
    'customer_agent,customer_general': {
      canSendMessage: true,
      messageSource: { customer: false, 'cs-agent': true, agent: true },
    },
    customer_general: {
      canSendMessage: true,
      messageSource: { customer: false, 'cs-agent': true, agent: true },
    },
    merchant_driver: {
      canSendMessage: false,
      messageSource: { merchant: false, driver: true },
    },
    merchant_agent: {
      canSendMessage: true,
      messageSource: { merchant: false, 'cs-agent': true, agent: true },
    },
    'merchant_agent,customer_general': {
      canSendMessage: true,
      messageSource: { merchant: false, 'cs-agent': true, agent: true },
    },
    customer_driver: {
      canSendMessage: false,
      messageSource: { customer: false, driver: true },
    },
    customer_merchant: {
      canSendMessage: false,
      messageSource: { customer: false, merchant: true },
    },
  };

  //   sides[chatSource].canSendMessage
  // sides[chatSource].messageSource[chatContent.messageSource]
  const renderMessage = (chatContent, index) => {
    const isRight = sides[source]?.messageSource[chatContent.messageSource];
    const isPrevMessageIdSame =
      chatContent.createdById === messages[index - 1]?.createdById;
    const isNextMessageIdSame =
      chatContent.createdById === messages[index + 1]?.createdById;
    const chatTime = moment(chatContent.createdAt).format('hh:mm A');
    const bubbleColor = (
      isRight,
      loading,
      error,
      isSystemMessage,
      messageSource,
    ) => {
      if (loading) {
        return '#1677ff55';
      } else if (messageSource === 'bot' && !isSystemMessage) {
        return '#1AC542';
      } else if (error) {
        return '#ff0000aa';
      } else if (isRight) {
        return '#1677ff';
      } else if (isSystemMessage) {
        return 'rgba(250, 141, 41, 0.10)';
      }
      return colorLocalStorage?.darkMode?'#c2c2c222':'#eee';
    };

    const getProductName = product => {
      if (product) {
        if (typeof product.product_name === 'string') {
          return product.product_name;
        } else if (typeof product.product_name === 'object') {
          return product.product_name.en;
        } else if (
          product.product_name_object &&
          Object.keys(product.product_name_object).length
        ) {
          return product.product_name_object.en;
        }
        return product.product_id;
      }
      return '';
    };
    if (chatContent.chatType === 'issueCard') {
      return (
        <div
          style={{
            alignSelf:"flex-start",
            marginTop: 10,
            marginBottom: 10,
            border: '1px solid #000',
            padding: '10px',
            width: '60%',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
          }}
        >
          <p
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '15px',
              borderBottom: '1px dashed #000',
            }}
          >
            {chatContent.chatMessage}
          </p>
          {!!chatContent.metaData?.issue?.products && _.map(JSON.parse(chatContent.metaData.issue.products), (item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  gap: '10px',
                  padding: '10px',
                  borderRadius: '10px',
                  alignItems: 'center',
                }}
              >
                <p style={{ fontWeight: 'bold' }}>{item.quantity}x</p>

                <Image
                src={item?.product_image_url}
                  style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'red',
                    borderRadius: '10px',
                  }}
                />
                {/* <p style={{ fontWeight: 'bold' }}>{item?.product_name['en']}</p> */}
                <p style={{ fontWeight: 'bold' }}>{
                  getProductName(item)
                      }</p>
              </div>
            );
          })}
          <div
            style={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: '15px',
              borderBottom: '1px dashed #000',
            }}
          ></div>
          {!!(!!chatContent?.metaData?.issue?.images || !!chatContent?.metaData?.issue?.text) && <p
            style={{
              color: 'gray',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            Photo And description
          </p>}
          <div>
            {!!chatContent?.metaData?.issue?.images && JSON.parse(chatContent.metaData.issue.images)?.map(image =>{
              return <Image src={image} alt="issue image" width={100} height={100}/>
            })}
          </div>
          <p>
            {chatContent?.metaData?.issue?.text}
          </p>
        </div>
      );
    }

    return (
      <div
        key={index}
        style={{
          paddingInline: '20px',
          paddingBlock: '2px',
        }}
      >
        {renderTimeAgo(chatContent.createdAt, messages[index + 1]?.createdAt)}
        {!isNextMessageIdSame && !chatContent.isSystemMessage ? (
          <div
            style={{
              opacity: '0.5',
              fontWeight: 'bold',
              fontSize: '12px',
              marginBottom: '5px',
              display: 'flex',
              justifyContent: isRight ? 'flex-end' : 'flex-start',
            }}
          >
            {chatContent.createdByName}
          </div>
        ) : null}
        <div
          key={chatContent._id}
          style={{
            justifyContent: isRight ? 'flex-end' : 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '10px',
              flexDirection: isRight ? 'row-reverse' : 'row',
            }}
          >
            {!isNextMessageIdSame && !chatContent.isSystemMessage ? (
              <div
                style={{
                  width: '40px',
                  // height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'end',
                }}
              >
                <img
                  style={{
                    width: '40px',
                    height: '40px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                  }}
                  src={
                    !isRight
                      ? returnImageSource(
                          selectedChatroom.roomType,
                          selectedChatroom.originalChatRoomImage ||
                            selectedChatroom.chatRoomImage,
                        )
                      : chatContent.createdByImage
                  }
                  alt=""
                />
              </div>
            ) : (
              <div
                style={{
                  width: '40px',
                  objectFit: 'cover',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                  display: 'flex',
                }}
              ></div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                padding: '7px',
                borderRadius: '10px',
                backgroundColor: bubbleColor(
                  isRight,
                  chatContent?.loading,
                  chatContent?.error,
                  chatContent?.isSystemMessage,
                  chatContent?.messageSource,
                ),
                color: isRight ? 'white' : 'black',
                width: chatContent?.isSystemMessage ? '100%' : '',
                maxWidth: '260px',
                minWidth: '120px',
                margin: chatContent?.isSystemMessage ? '20px auto' : '',
                color: chatContent?.isSystemMessage ? '#FA8D29' : '',
                border: chatContent?.isSystemMessage ? '1px solid #FA8D29' : '',
                fontWeight: chatContent?.isSystemMessage ? 'bold' : '',
                textAlign: chatContent?.isSystemMessage ? 'center' : '',

                ////is left
                borderEndStartRadius:
                  !isRight &&
                  !chatContent?.isSystemMessage &&
                  !isNextMessageIdSame &&
                  isPrevMessageIdSame
                    ? '0px'
                    : '10px',

                borderStartStartRadius:
                  !isRight &&
                  !chatContent?.isSystemMessage &&
                  !isPrevMessageIdSame &&
                  isNextMessageIdSame
                    ? '0px'
                    : '10px',

                ////is Right
                borderEndEndRadius:
                  isRight &&
                  !chatContent?.isSystemMessage &&
                  !isNextMessageIdSame &&
                  isPrevMessageIdSame
                    ? '0px'
                    : '10px',

                borderStartEndRadius:
                  isRight &&
                  !chatContent?.isSystemMessage &&
                  !isPrevMessageIdSame &&
                  isNextMessageIdSame
                    ? '0px'
                    : '10px',
              }}
            >
              {chatContent.chatType === 'image' ? (
                <Image src={chatContent.chatMessage} width={200} />
              ) : chatContent.chatType === 'coordinate' ? (
                <div
                  style={{
                    width: '240px',
                    height: '260px',
                  }}
                >
                  <ChatMap chat={chatContent} />
                  <Button
                    style={{
                      width: '100%',
                    }}
                    type="primary"
                    loading={loadingForChangeLocation}
                    onClick={async() => {
                      if (chatContent?.chatMessage) {
                        try {
                         await  requestForChangeLocation({
                            statusId: 19,
                            orderId: selectedChatroom.roomRef,
                            orderLongitude:
                              chatContent.chatMessage?.split(',')[1],
                            orderLatitude: chatContent.chatMessage?.split(',')[0],
                          })
                          alertMessage.success(
                          'Updated location'
                          );
                        } catch (error) {
                          alertMessage.error(error?.response?.data?.msg||"Error while changing order status");
                          
                        }
                 
                      }
                    }}
                  >
                    Change location
                  </Button>
                </div>
              ) : (
                <span
                  style={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                    color: isRight && '#fff',
                  }}
                >
                  {chatContent.chatMessage}
                  {chatContent?.isSystemMessage ? `: ${chatTime} ` : ''}
                </span>
              )}

              {!chatContent.isSystemMessage ? (
                <div
                  style={{
                    fontSize: '10px',
                    color: isRight ? 'white' :undefined,
                    // float: 'right',
                    alignSelf: 'flex-end',
                  }}
                >
                  {chatTime}
                </div>
              ) : null}
            </div>
          </div>
          {chatContent?.error ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '50px',
              }}
              onClick={() => resendMessage(chatContent)}
            >
              click to resend
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        width: '100%',
        // backgroundColor: 'white',
        paddingBlock: '10px',
        height: '100%',
        marginTop: -15,
      }}
    >
      {/* Header */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingInline: '20px',
          borderBottom: '3px solid #4A5486',
          paddingBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <img
            src={returnImageSource(
              selectedChatroom.roomType,
              selectedChatroom.originalChatRoomImage ||
                selectedChatroom.chatRoomImage,
            )}
            alt=""
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'cover',
              borderRadius: '50px',
              border: '1.5px solid  #000',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <a
                style={{
                  color:colorLocalStorage?.darkMode?'white': '#171C1B',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'inline-block',
                  maxWidth: '200px', // Adjust the maximum width as needed
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(
                    selectedChatroom.roomType === 'order'
                      ? selectedChatroom.roomRef
                      : selectedChatroom.originalRoomName ||
                          selectedChatroom.roomName,
                  );
                  alertMessage.success('Copied to clipboard');
                }}
              >
                {selectedChatroom.roomType === 'order'
                  ? selectedChatroom.roomRef
                  : selectedChatroom.originalRoomName ||
                    selectedChatroom.roomName}
              </a>
              <Tag color="black" style={{ color: 'white' }}>
                {selectedChatroom?.roomAssignedToName || 'Unassigned'} {selectedChatroom?.roomTeam ? '('+ selectedChatroom?.roomTeam + ')': ''}
              </Tag>
              {/* {onlineUsers?.find((item) => item.type === 'customer') ? (
                <span style={{ color: '#465352' }}>
                  <svg width="10" height="11" style={{ marginRight: '4px' }}>
                    <rect
                      y="0.5"
                      width="10"
                      height="10"
                      rx="5"
                      fill="#00B307"
                    />
                  </svg>
                  Online
                </span>
              ) : (
                <span style={{ color: '#465352' }}>
                  <svg width="10" height="11" style={{ marginRight: '4px' }}>
                    <rect
                      y="0.5"
                      width="10"
                      height="10"
                      rx="5"
                      fill="#E4343E"
                    />
                  </svg>
                  Offline
                </span>
              )} */}
            </span>
          </div>
          {/* {auth.user.isMonitorAgent ||
          auth.user.isSuperAdmin ||
          auth.user.isSupervisorAgent ? (
            <Tooltip title="Re-Assign Chat">
              <UserSwitchOutlined
                size="25"
                color="#4A5486"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => setOpenReAssignChatModal(true)}
              />
            </Tooltip>
          ) : null} */}
        </div>

        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <BookmarkChat
            chatSource={source}
            roomId={selectedChatroom?._id}
            setSelectedChatroom={setSelectedChatroom}
            selectedChatroom={selectedChatroom}
          />
          {!disableActions &&
          sides[source]?.canSendMessage &&
          selectedChatroom?.status == 'opened' ? (
            <Tooltip title="Resolve Chatroom">
              <UilCheckCircle
                size="25"
                color="#4A5486"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => onResolveChatroom()}
              />
            </Tooltip>
          ) : null}
          {!isDrawer && (
            <Tooltip title="Close Chatroom">
              <CloseOutlined
                onClick={() => {
                  setSelectedChatroom(null);
                }}
                style={{
                  cursor: 'pointer',
                  fontSize: '20px',
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>

      {/* chat contents */}
      <div
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          maxHeight:
            disableActions && sides[source]?.canSendMessage
              ? 'calc(100vh - 220px)'
              : 'calc(100vh - 320px)',
          position: 'relative',
        }}
        id="chat-content"
      >
        {chatroomMessagesLoading ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div
            id="scrollableDiv"
            style={{
              height: '99%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={onLoadMoreMessages}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              hasMore={!canNotLoadMore}
              inverse={true}
              loader={
                canNotLoadMore ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ color: 'grey' }}>No more messages</span>
                  </div>
                ) : (
                  <Skeleton
                    avatar
                    paragraph={{ rows: 1 }}
                    active
                    style={{
                      // paddingLeft: '10px',
                      paddingRight: '90px',
                      width: '90%',
                      alignSelf: 'center',
                    }}
                  />
                )
              }
              endMessage={
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <span style={{ color: 'grey', marginBottom: 25 }}>
                    No more messages
                  </span>
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {isTyping ? (
                <div
                  style={{
                    flexDirection:
                      isTyping?.userType == 'agent' ? 'row-reverse' : 'row',
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      [isTyping?.userType == 'agent'
                        ? 'marginRight'
                        : 'marginLeft']: '66px',

                      objectFit: 'cover',
                      marginTop: '10px',
                      marginBottom: '10px',
                      backgroundColor:
                        isTyping?.userType == 'agent' ? '#1677FF' : '#EEEEEE',
                      color: isTyping?.userType == 'agent' ? '#fff' : '#000',
                      borderRadius: '30px',
                      padding: 10,

                      // marginLeft: '10px',
                    }}
                  >
                    {isTyping?.text && auth.user.isSuperAdmin ? (
                      <span
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {isTyping.text}
                      </span>
                    ) : (
                      ''
                    )}

                    <img
                      style={{
                        width: '45px',
                        height: '20px',
                        objectFit: 'cover',
                        marginBottom: '-10px',
                        color: 'red',
                        borderRadius: '30px',

                        // marginLeft: '10px',
                      }}
                      src={typingMessage}
                    />
                  </div>
                  <div
                    style={{
                      width: '45px',
                      height: '20px',
                      objectFit: 'cover',
                      marginBottom: '-10px',

                      borderRadius: '30px',

                      // marginLeft: '10px',
                    }}
                  ></div>
                </div>
              ) : null}
              {messages?.map((chatContent, index) => {
                return renderMessage(chatContent, index);
              })}
            </InfiniteScroll>
          </div>
        )}
      </div>

      {/* chat actions */}
      {!disableActions && sides[source]?.canSendMessage && (
        <div
          style={{
            display: 'flex',
            padding: '10px',
            borderTop: '1px solid #e9e9e9',
            paddingTop: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            // backgroundColor: 'white',
            // backgroundColor: 'green',
          }}
        >
          <Upload
            customRequest={async (options) => {
              const { onSuccess, onError, file, onProgress } = options;
              try {
                const formData = new FormData();
                formData.append('image', file);
                const imageUrl = await uploadImage(formData);
                await sendMessage(imageUrl, 'image');
                onSuccess('Ok');
              } catch (err) {
                onError({ err });
              }
            }}
          >
            <UilPaperclip
              style={{
                fontSize: '20px',
                cursor: 'pointer',
                color: '#2980b999',
                // backgroundColor: 'red',
                marginTop: '8px',
                marginRight: '10px',
                marginLeft: '10px',
                // borderRadius: '50%',
              }}
            />
          </Upload>
          <textarea
            onKeyDown={(e) => {
              if (e.keyCode == 13 && e.shiftKey == false && message) {
                e.preventDefault();
                sendMessage(message.trim());
              }
            }}
            // onKeyUp={(e) => {
            //   if (e.target.scrollHeight < 130) {
            //     e.target.style.height = '1px';
            //     e.target.style.height = 25 + e.target.scrollHeight + 'px';
            //   }
            // }}
            onChange={(e) => {
              setMessage(e.target.value);
              sendIsTyping(e.target.value);
              // typing();
            }}
            value={message}
            placeholder="Type something to send... "
            style={{
              flex: 1,
              border: 'none',
              outline: 'none',
              textAlign: 'start',
              padding: '10px',
              // resize: 'none',
              height:'auto !important',
              color:colorLocalStorage?.darkMode?'white': 'black',
              backgroundColor:colorLocalStorage?.darkMode?'#f2f2f244': undefined
            }}
            id="messageBox"
          ></textarea>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <UilMessage
              style={{
                padding: '7px',
                width: '40px',
                height: '40px',
                transform: 'rotate(-45deg)',
                marginLeft: '10px',
                backgroundColor: '#2980b9',
                color: 'white',
                borderRadius: '50%',
              }}
              onClick={() => message && sendMessage(message)}
            />
          </div>
        </div>
      )}
      {openReAssignChatModal ? (
        <MakeGroupOrderModal
          open={openReAssignChatModal}
          setState={setOpenReAssignChatModal}
          roomRef={selectedChatroom?.roomRef}
          chatSource={source}
        />
      ) : null}
    </div>
  );
}

export default ChatMessageTabs;

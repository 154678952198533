import React, { useRef, useState } from 'react';
import {
  Table,
  Tag,
  Button,
  Input,
  Select,
  Space,
  message,
  Tooltip,
  Popconfirm,
  Row,
  Switch,
} from 'antd';
import {
  UserOutlined,
  MessageOutlined,
  FileTextOutlined,
} from '@ant-design/icons'; // Import Ant Design Icons
import {
  useAxiosChatPut,
  useAxiosGetV2,
  useAxiosPut,
} from '../../../configs/axios';
import supportTeamMetricsFunctions from '../SupportTeamMetricsFunctions';
import dayjs from 'dayjs';

var updateLocale = require('dayjs/plugin/updateLocale');

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1m',
    m: '1m',
    mm: '%dMs',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mon',
    MM: '%dmon',
    y: '1y',
    yy: '%dy',
  },
});

const { Option } = Select;

const AgentActivityTable = () => {
  const [statusLoading, setStatusLoading] = useState(false);
  // Fetch the agents list with autoRun triggering re-fetch based on filters
  const {
    data: agents,
    loading: loadingAgents,
    request: refetch,
  } = useAxiosGetV2('/OperationTasks/v2/activityAgents', {
    autoRun: true,
    search: '', // Default search, empty initially
    status: '', // Default status, empty initially
  });

  const { request: unAssignChat } = useAxiosPut(
    '/OperationTasks/unAssignAllUserChatRooms',
    { autoRun: false },
  );
  const { request: unAssignTicket } = useAxiosPut(
    '/OperationTasks/unAssignAllTickets',
    { autoRun: false },
  );

  const { request: updateStatus } = useAxiosChatPut('agents/changeActive', {});

  // Handle Reassigning Chats
  const handleReassignChats = async (userId) => {
    try {
      await unAssignChat({
        userId,
      });
      message.success('Chats unAssigned Successfully');
    } catch (error) {
      message.error('Error in unAssigning Chats');
    }
  };

  // Handle Reassigning Tickets
  const handleReassignTickets = async (userId) => {
    try {
      await unAssignTicket({
        userId,
      });
      message.success('Tickets unAssigned Successfully');
    } catch (error) {
      message.error('Error in unAssigning Tickets');
    }
  };
  const onChangeStatus = async (agentId, checked, type) => {
    setStatusLoading(true);
    try {
      await updateStatus({
        agentId,
        [type]: checked.toString(),
      });
      supportTeamMetricsFunctions.getSupportTeamMetrics();
      refetch();
      message.success('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      message.error('Failed to update status');
    } finally {
      setStatusLoading(false);
    }
  };

  // Table columns
  const columns = [
    {
      title: (
        <span>
          <UserOutlined style={{ marginRight: 8 }} />
          User Name
        </span>
      ),
      dataIndex: 'userName',
      key: 'userName',
    },

    {
      title: 'Is Active (Chat)',
      responsive: ['sm'],
      rowSpan: 2,
      dataIndex:'isInChatQueue',
      key: 'activeChats',
      align: 'center',
      render: (e,agent) => {
        return (
          <Switch
            loading={statusLoading}
            checked={e}
            onChange={(checked) => {
              onChangeStatus(agent?._id, checked, 'isInChatQueue');
            }}
          />
        );
      },
    },

    {
      title: 'Is Active (Ticket)',
      responsive: ['sm'],
      rowSpan: 2,
      key: 'activeTickets',
      dataIndex:'isGettingTicket',
      align: 'center',
      render: (e,agent) => {
        return (
          <Switch
            loading={statusLoading}
            checked={e}
            onChange={(checked) => {
              onChangeStatus(agent?._id, checked, 'isGettingTicket');
            }}
          />
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Last Active',
      dataIndex: 'last_active_time',
      key: 'last_active_time',
      render: (Active) => (
        <Tag
          color={dayjs().diff(dayjs(Active), 'minute') < 3 ? 'green' : 'red'}
        >
          {dayjs().diff(dayjs(Active), 'minute')}m ago
        </Tag>
      ),
    },
    {
      title: (
        <span>
          <FileTextOutlined style={{ marginRight: 8 }} />
          Tickets
        </span>
      ),
      dataIndex: 'assignedTask',
      key: 'assignedTask',
      render: (assignedTask) => (
        <Tag color={assignedTask > 0 ? 'orange' : 'red'}>
          {assignedTask > 0 ? `${assignedTask} Assigned` : 'Unassigned'}
        </Tag>
      ),
    },
    {
      title: (
        <span>
          <MessageOutlined style={{ marginRight: 8 }} />
          Chats
        </span>
      ),
      dataIndex: 'assignedRoom',
      key: 'assignedRoom',
      render: (assignedRoom) => (
        <Tag color={assignedRoom > 0 ? 'orange' : 'red'}>
          {assignedRoom > 0 ? `${assignedRoom} Assigned` : 'Unassigned'}
        </Tag>
      ), 
    },
    {
      title: 'Agent Type',
      dataIndex: 'agentType',
      key: 'agentType',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Row gutter={[10, 10]}>
          <Tooltip title="Reassign Chats">
            <Popconfirm
              title="Are you sure you want to reassign chats?"
              onConfirm={() => handleReassignChats(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small" style={{ marginRight: 8 }}>
                Reassign Chats
              </Button>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Reassign Tickets">
            <Popconfirm
              title="Are you sure you want to reassign tickets?"
              onConfirm={() => handleReassignTickets(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small">
                Reassign Tickets
              </Button>
            </Popconfirm>
          </Tooltip>
        </Row>
      ),
    },
  ];
  const timer = useRef(null);

  const debounce = (filters, delay) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      refetch(filters);
    }, delay);
  };

  const currentFilters = useRef({
    search: '',
    status: '',
  });

  const handleFilterChange = (value, type) => {
    const newFilters = { ...currentFilters.current, [type]: value };
    currentFilters.current = newFilters;

    if (type === 'search') {
      debounce(newFilters, 500);
    } else if (type === 'status') {
      refetch(newFilters);
    }
  };
  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search by User Name"
          prefix={<UserOutlined />}
          onChange={(e) => handleFilterChange(e.target.value, 'search')}
        />
        <Select
          name="Status"
          placeholder="Select Status"
          onChange={(value) => handleFilterChange(value, 'status')}
          style={{ width: 120 }}
        >
          <Option value="online">Online</Option>
          <Option value="offline">Offline</Option>
        </Select>
      </Space>

      {/* Table Section */}
      <Table
        dataSource={agents || []} // Ensure agents is an array, fetched from the API
        columns={columns}
        loading={loadingAgents} // Show loading spinner while data is being fetched
        pagination={{
          pageSize: 10,
          page: agents?.page || 1,
          total: agents?.data?.length || 0,
          pageSizeOptions: ['10', '15', '20', '30', '50'] || '15',
        }}
        rowKey="_id"
      />
    </div>
  );
};

export default AgentActivityTable;

import { Col, Drawer, Form, Image, message, Row, Spin } from 'antd';
import { Tag, Button, Tooltip } from 'antd';
import classes from './style/task-board-drawer.module.css';
import Merchant from './icons/Merchant';
import Customer from './icons/Customer';
import Driver from './icons/Driver';
import MasonryGrid from './MasonryGrid.jsx';
import {
  useAxiosGetTaskManagement,
  useAxiosPostTaskManagement,
  useAxiosTaskManagementGetV2,
  useAxiosTaskManagementPut,
} from '../../configs/axios';
import dayjs from 'dayjs';
import Phone from './icons/Phone';
import Chat from './icons/Chat';
import MenuIcon from './icons/MenuIcon';
import RenderMenu from './RenderMenu';
import CheckedIcon from './icons/Check.jsx';
import { SendOutlined } from '@ant-design/icons';
import SingleOrderCard from './OrderCard.jsx';
import TaskCollapse from './TaskCollapse.js';
import EditMerchantStatusModal from './EditMerchantWorkingStatus.jsx';
import { useReducer, useRef } from 'react';
import './../../../node_modules/video-react/dist/video-react.css'; // import css
import { isArray } from 'lodash';
import RemoteSelect from '../utility/RemoteSelect.jsx';
import CustomMention from './CustomMention.jsx';
import SendToOtherDepartment from './TaskDrawer/SendToOtherDepartment.jsx';

var updateLocale = require('dayjs/plugin/updateLocale');

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'less than 1 min',
    m: '1 min',
    mm: '%d Mins',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

const TaskDrawer = ({
  task,
  onClose,
  onOpenOrderMap,
  onOpenChangeOrderStatusModal,
  onOpenOrderCart,
  onOpenRefund,
  onOpenOrderEdit,
  onOpenAssignCourier,
  onOpenDrawerChat,
  onOpenMerchantInfo,
  copyToClipboard,
  onOpenTaskDrawer,
  board,
}) => {
  const initValue = {
    showEditWorkingStatusModal: false,
    showSendToOtherDepartment: false,
  };
  const commentInput = useRef();
  const selectedAgent = useRef([]);
  const [form] = Form.useForm();

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );
  const onCloseEditWorkingStatusModal = () => {
    setState({
      showEditWorkingStatusModal: false,
    });
  };
  const onOpenEditWorkingStatusModal = () => {
    setState({
      showEditWorkingStatusModal: true,
    });
  };

  const { data: dataTasks, request: getTasks } = useAxiosTaskManagementGetV2(
    '/lezzooBoard/getSingleTask',
    {
      autoRun: true,
      taskIssueId: task.operation_task_id,
    },
  );

  const { request: sendCommentRequest, loading: sendCommentLoading } =
    useAxiosPostTaskManagement('/myTasksLezzooBoard/comment', {
      autoRun: false,
    });
  const { request: requestKanbanStatus, loading: requestKanbanStatusLoading } =
    useAxiosTaskManagementPut('/lezzooBoard/updateTask', {
      autoRun: false,
    });
  const { data: currentOrder, request: getCurrentOrder } =
    useAxiosGetTaskManagement('/orders/getOrderWithOperationTaskId', {
      operation_task_id: task.operation_task_id,
      autoRun: task.issue_source_type && task.issue_source_type !== 'product',
    });

  const [sendCommentForm] = Form.useForm();

  const onFinishSendComment = async (values) => {
    const userNames = selectedAgent.current?.map((agent) => agent.value);
    const body = {
      taskId: task.operation_task_id,
      comment: commentInput.current,
      userNames,
    };

    await sendCommentRequest(body);
    sendCommentForm.resetFields();
    commentInput.current = '';
    selectedAgent.current = [];
    await getTasks();
  };

  const onOpenActionMenuChange = async (open) => {
    if (!open) {
      return;
    }
    if (dataTasks.issue_source_type == 'order' && !currentOrder) {
      const data = await getCurrentOrder({
        orderId: dataTasks.issue_source_id,
      });
    }
  };

  const onOpenSendToOtherDepartment = () => {
    setState({
      showSendToOtherDepartment: true,
    });
  };
  const onCloseSendToOtherDepartment = () => {
    setState({
      showSendToOtherDepartment: false,
    });
  };

  return (
    <Drawer
      placement="right"
      closable={true}
      title={`#${task.operation_task_id}`}
      onClose={onClose}
      open={true}
      width={'60vw'}
    >
      {!dataTasks ? (
        <Spin />
      ) : (
        <div
          onClick={() => onOpenTaskDrawer(dataTasks)}
          className={classes.taskCard}
        >
          <div className={classes.cardHeader}>
            <div className={classes.left}>
              <div>
                <h3 className={classes.headerColor}>
                  {dataTasks.task_issue_name}
                </h3>
                {dataTasks.task_comment && (
                  <h5
                    style={{
                      color: 'grey',
                      marginTop: '2px',
                    }}
                  >
                    {dataTasks.task_comment}
                  </h5>
                )}
              </div>
              <div
                style={{
                  marginLeft: '15px',
                }}
              >
                <div className={classes.lateTime}>
                  {dayjs().from(
                    dayjs(
                      dataTasks.task_status === 'todo'
                        ? dataTasks.created_at
                        : dataTasks.task_status === 'in_progress'
                        ? dataTasks.task_inprogress_at
                        : dataTasks.task_status === 'done'
                        ? dataTasks.updated_at
                        : dataTasks.task_done_at,
                    ),
                    true,
                  )}
                </div>
              </div>
            </div>

            <div className={classes.headerBadge}>
              <div
                onClick={() => {
                  copyToClipboard(dataTasks.issue_source_id);
                }}
                className={`${classes['ticketIdDev']} ${
                  classes[dataTasks.task_issue_party]
                }`}
              >
                {dataTasks.issue_source_type} #{dataTasks.issue_source_id}
              </div>
            </div>
            <div className={classes.menuButton}>
              <Tooltip
                title={
                  <RenderMenu
                    onOpenChangeOrderStatusModal={onOpenChangeOrderStatusModal}
                    currentOrder={currentOrder}
                    onOpenOrderMap={onOpenOrderMap}
                    onOpenOrderCart={onOpenOrderCart}
                    onOpenRefund={onOpenRefund}
                    onOpenOrderEdit={onOpenOrderEdit}
                    onOpenAssignCourier={onOpenAssignCourier}
                    onOpenMerchantInfo={onOpenMerchantInfo}
                  />
                }
                className={classes.tooltip}
                trigger={['click']}
                onOpenChange={onOpenActionMenuChange}
              >
                <div>
                  <MenuIcon />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classes.cardTags}>
            {dataTasks.tags?.map((tag, idx) => (
              <Tag
                className={classes.tag}
                color={tag === 'Loyal' ? 'blue' : 'purple'}
                key={idx}
              >
                {tag?.source_name}
              </Tag>
            ))}
          </div>
          <div className={classes.dividerClass} />
          {dataTasks?.task_description && (
            <div className={classes.descriptionContainer}>
              <h4>Description</h4>
              <p>{dataTasks?.task_description}</p>
            </div>
          )}

          <div className={classes.cardContent}>
            <div>
              {task?.merchant ? (
                <div>
                  <div className={classes.driverAndCustomer}>
                    <div className={classes.nameAndIcon}>
                      <Merchant />
                      <span
                        className={classes.sourceName}
                        onClick={() => {
                          copyToClipboard(dataTasks.merchant.source_id);
                        }}
                      >
                        {task?.merchant?.source_name}
                      </span>
                    </div>
                    <div>
                      <Tooltip
                        placement="top"
                        title={dataTasks?.merchant?.source_phone}
                      >
                        <Button
                          type="ghost"
                          icon={<Phone />}
                          onClick={() => {
                            copyToClipboard(
                              dataTasks?.objectMeta[0]?.source_phone,
                            );
                          }}
                        />
                      </Tooltip>
                      <Button
                        type="ghost"
                        icon={<Chat />}
                        onClick={() => {
                          onOpenDrawerChat(task, 'merchant');
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.dividerClass} />
                </div>
              ) : null}

              {task?.customer ? (
                <div className={classes.driverAndCustomer}>
                  <div className={classes.nameAndIcon}>
                    <div className={classes.bigIcon}>
                      <Customer />
                    </div>
                    <span
                      className={classes.sourceName}
                      onClick={() => {
                        copyToClipboard(dataTasks.customer.source_id);
                      }}
                    >
                      {task?.customer?.source_name}
                    </span>
                  </div>
                  <div>
                    <Tooltip
                      placement="top"
                      title={dataTasks?.customer?.source_phone}
                    >
                      <Button
                        type="ghost"
                        icon={<Phone />}
                        onClick={() => {
                          copyToClipboard(
                            dataTasks?.objectMeta[0]?.source_phone,
                          );
                        }}
                      />
                    </Tooltip>
                    <Button
                      type="ghost"
                      icon={<Chat />}
                      onClick={() => {
                        onOpenDrawerChat(task, 'customer');
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {task?.driver ? (
                <div className={classes.driverAndCustomer}>
                  <div className={classes.nameAndIcon}>
                    <div className={classes.bigIcon}>
                      <Driver />{' '}
                    </div>

                    <span
                      className={classes.sourceName}
                      onClick={() => {
                        copyToClipboard(dataTasks.driver.source_id);
                      }}
                    >
                      {task?.driver?.source_name}
                    </span>
                  </div>
                  <div>
                    <Tooltip
                      placement="top"
                      title={dataTasks?.driver?.source_phone}
                    >
                      <Button
                        type="ghost"
                        icon={<Phone />}
                        onClick={() => {
                          copyToClipboard(dataTasks?.driver?.source_phone);
                        }}
                      />
                    </Tooltip>
                    <Button
                      type="ghost"
                      icon={<Chat />}
                      onClick={() => {
                        onOpenDrawerChat(task, 'driver');
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className={classes.footerStyle}>
          <div className={classes.row}>
            <Button
              className={`${classes[statusCode]} `}
              onClick={() => {
                changeTaskStatus({
                  operation_task_id: dataTasks.operation_task_id,
                  task_status: statusCode,
                  task_comment: inputValue.current,
                });
              }}
              loading={changeTaskLoading}
            >
              <ToInProgress className={classes.buttonStatusIcon} /> {statusText}
            </Button>
          </div>
          <input
            onChange={(e) => {
              inputValue.current = e.target.value;
            }}
            className={classes.inputStyle}
            placeholder="Share your insights !"
          />
          <div className={classes.row}>
            <Calendar />
            <span className={classes.ticketTimer}>
              {dayjs().from(dayjs(dataTasks.created_at), true)}
            </span>
          </div>
        </div> */}

          {dataTasks?.products?.length
            ? dataTasks.products.map((product) => (
                <div>
                  <div
                    className={`${classes.merchantContainer} ${classes.hasChat}`}
                  >
                    <div className={classes.nameAndIcon}>
                      <Image
                        src={product?.source_image}
                        alt="Merchant"
                        width={35}
                        className={classes.imageStyle}
                      />
                      <div className={classes.merchantTextContainer}>
                        <div
                          className={`${classes.row} ${classes.merchantNamecontainer}`}
                        >
                          <span
                            className={classes.merchantName}
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(product.source_id);
                            }}
                          >
                            {product?.source_name}{' '}
                            {product?.source_city
                              ? `(${product?.source_city})`
                              : ''}
                          </span>
                        </div>
                        <div className={classes.subtitleContainer}>
                          <span className={classes.merchantAccountManager}>
                            #{product?.source_id}
                          </span>
                          <Tag color="purple">{product.source_subtitle}</Tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}

          {/*  ticket status  */}
          <div className={classes.ticketLogStatusContainer}>
            <div className={classes.columnCenter}>
              <div
                className={
                  dataTasks?.log?.in_progress || dataTasks?.log?.done
                    ? classes['inTodoActive']
                    : classes['inTodoInActive']
                }
              >
                <CheckedIcon />
              </div>
              <div type="vertical" className={classes.todoLine} />
              <div
                className={
                  dataTasks?.log?.done
                    ? classes['inProgressActive']
                    : classes['inProgressInActive']
                }
              >
                <CheckedIcon />
              </div>
              <div type="vertical" className={classes.inProgressLine} />
              <div
                className={
                  dataTasks?.log?.done
                    ? classes['doneActive']
                    : classes['doneInActive']
                }
              >
                <CheckedIcon />
              </div>
            </div>
            <div className={classes.columnRight}>
              <div className={classes.todoDetails}>
                <div className={classes.statusTextContainer}>
                  <div>
                    <h5>Agent Not Assigned</h5>
                    <h6>
                      Task in todo for{' '}
                      {dayjs().from(
                        dayjs(
                          dataTasks?.log?.in_progress?.created_at ||
                            dataTasks?.log?.done?.created_at ||
                            dataTasks?.created_at,
                        ),
                        true,
                      )}
                    </h6>
                  </div>
                  <span className={classes.dateSpan}>
                    {dayjs(dataTasks?.created_at).format('DD/MM/YYYY hh:mm A')}
                  </span>
                </div>
              </div>
              <div className={classes.inProgressDetails}>
                {dataTasks?.log?.in_progress ? (
                  <div className={classes.statusTextContainer}>
                    <div>
                      <h5>{dataTasks?.log?.in_progress?.created_by_name}</h5>
                      <h6>
                        {dataTasks?.log?.in_progress?.created_by_name} accepted
                        issue after{' '}
                        {dayjs(dataTasks?.created_at).from(
                          dayjs(dataTasks?.log?.in_progress?.created_at),
                          true,
                        )}
                      </h6>
                    </div>
                    <span className={classes.dateSpan}>
                      {dayjs(dataTasks?.log?.in_progress?.created_at).format(
                        'DD/MM/YYYY hh:mm A',
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className={classes.doneDetails}>
                {dataTasks?.log?.done ? (
                  <div className={classes.statusTextContainer}>
                    <div>
                      <h5>{dataTasks?.log?.done?.created_by_name}</h5>
                      <h6>
                        {dataTasks?.log?.done?.created_by_name} completed issue
                        after{' '}
                        {dayjs(
                          dataTasks?.dataTasks?.log?.in_progress?.created_at,
                        ).from(dayjs(dataTasks?.log?.done?.created_at), true)}
                      </h6>{' '}
                    </div>
                    <span className={classes.dateSpan}>
                      {dayjs(dataTasks?.log?.done?.created_at).format(
                        'DD/MM/YYYY hh:mm A',
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <Row gutter={16} align="middle" style={{ marginBottom: '16px' }}>
            <Col span={4}>
              <Button
                type="primary"
                size="large"
                block
                onClick={onOpenSendToOtherDepartment}
                style={{
                  backgroundColor: '#FF4D4F',
                  borderColor: '#FF4D4F',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease',
                }}
              >
                {'ReAssign Task'}
              </Button>
            </Col>

            <Col span={8}>
              {dataTasks?.task_issue_id ? (
                <RemoteSelect
                  labelOption="board_name_en"
                  optionText="board_code"
                  placeholder="Change Status"
                  allowClear={true}
                  endpoint="/lezzooBoard/getDepartmentBoards"
                  valueOption={'board_code'}
                  useFetch={useAxiosTaskManagementGetV2}
                  style={{ width: '100%' }}
                  query={{
                    issueId: dataTasks?.task_issue_id,
                  }}
                  onValueChange={async ({ key }) => {
                    try {
                      await requestKanbanStatus({
                        taskId: dataTasks?.operation_task_id,
                        goto: key,
                      });
                      message.success('Status Updated Successfully');
                    } catch (error) {
                      message.error('Status Update Failed');
                      console.log(error);
                    }
                    onClose();
                  }}
                  loading={requestKanbanStatusLoading}
                />
              ) : null}
            </Col>
          </Row>
          <div>
            <MasonryGrid
              mediaData={dataTasks?.media}
              columnCount={3}
              preview={true}
            />
          </div>
          <div className={classes.orderContainer}>
            {currentOrder &&
              isArray(currentOrder) &&
              currentOrder.map((order) => (
                <div className={classes.orderCard}>
                  <SingleOrderCard single={order} />
                </div>
              ))}
          </div>
          {dataTasks?.comments?.map((comment, index) => (
            <div className={classes.commentContainer}>
              <div className={classes.commentHeader}>
                <h3>{comment.created_by_name}</h3>
                <h4>{dayjs().from(comment.created_at)}</h4>
              </div>
              <div className={classes.dividerClass} />

              <div className={classes.commentContent}>
                <p>{comment.comment}</p>
              </div>
            </div>
          ))}
          <Form form={sendCommentForm} onFinish={onFinishSendComment}>
            <Form.Item name="comment">
              <div className={classes.commentInputContainer}>
                <CustomMention
                  comment={commentInput}
                  selectedUser={selectedAgent}
                />
                <Button
                  htmlType="submit"
                  type="ghost"
                  className={classes.sendButton}
                  loading={sendCommentLoading}
                  icon={<SendOutlined />}
                />
              </div>
            </Form.Item>
          </Form>

          <TaskCollapse
            tasks={dataTasks}
            copyToClipboard={copyToClipboard}
            onOpenEditWorkingStatusModal={onOpenEditWorkingStatusModal}
          />
        </div>
      )}
      {state.showSendToOtherDepartment && (
        <SendToOtherDepartment
          task={task}
          open={state.showSendToOtherDepartment}
          onClose={onCloseSendToOtherDepartment}
        />
      )}
      {state.showEditWorkingStatusModal && (
        <EditMerchantStatusModal
          merchant={{
            merchant_id: task.merchant.source_id,
            merchant_status:
              dataTasks?.merchantWorkingStatus?.merchant_working_status,
          }}
          visible={state.showEditWorkingStatusModal}
          setVisible={onCloseEditWorkingStatusModal}
        />
      )}
    </Drawer>
  );
};

export default TaskDrawer;

import { Modal } from 'antd';
import React, { useContext } from 'react';
import { OrderContext } from '../../pages/Home';
import { useAxiosGet } from '../../configs/axios';

function OrderAmountDetailsComponent(props) {
  const { status, setStatus } = props;
  const { selectedOrder } = useContext(OrderContext);
    const { data: cartExtraData, request: requestOrderFee } = useAxiosGet('/orders/getCartExtraData', {
      autoRun: true,
      orderId: selectedOrder.orderId,
    });

    const minimumOrderDifference = cartExtraData?.orderAttributePrice?.find((fee) => fee.key_type === 'minimum_order_difference')?.amount || 0;

  return (
    <Modal
      title="Order Amount Details"
      destroyOnClose
      getContainer={false}
      open={status}
      onOk={() => {
        setStatus(false);
      }}
      onCancel={() => {
        setStatus(false);
      }}
      centered
      footer={null}
      width={400}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Price:</strong>
          <span>{selectedOrder.orderSubtotal}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Subscription Total:</strong>
          <span>{selectedOrder.orderSubscriptionTotal}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Discount:</strong>
          <span>{selectedOrder.orderDiscount}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Customer Delivery:</strong>
          <span>{selectedOrder.orderDeliveryPrice}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Service Price:</strong>
          <span>{selectedOrder.orderServicePrice}</span>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Driver Tip</strong>
          <span>{selectedOrder.orderDriverTipAmount}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Remaining cash:</strong>
          <span>{selectedOrder.orderRemainingCash}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Tax:</strong>
          <span>{selectedOrder.orderTax}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Fee:</strong>
          <span>{selectedOrder?.orderFee} IQD</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Minimum Order Difference:</strong>
          <span>{minimumOrderDifference} IQD</span>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <strong>Order Total:</strong>
          <span>{selectedOrder.orderTotal}</span>
        </div>
      </div>
    </Modal>
  );
}

export default OrderAmountDetailsComponent;

import { Button, Form, Input, message, Select } from 'antd';
import {  useAxiosPut } from '../../../configs/axios';
import RemoteSelectForm from '../../utility/RemoteSelectForm';

const RejectOrderForm = (props) => {
  const { closeModal, getAllOrders, ordersSearchFilters, selectedOrder } =
  props;
  const { loading: updateOrderLoading, request: updateOrderRequest } =
  useAxiosPut('orders/status');
  
  const [form] = Form.useForm();
  const orderRejectedOnType = Form.useWatch('orderRejectedOnType', form);
  const rejectReason = Form.useWatch('rejectionReason', form)?.label;

  const onRejectOrder = async (values) => {
    try {
      const requestBody = {
        statusId: 26,
        orderId: selectedOrder.orderId,
        orderRejectedOnType: values.orderRejectedOnType,
        comment:rejectReason == 'others' ? values.comment : rejectReason,
      };

      await updateOrderRequest(requestBody);
      message.success('Order Rejected');
      closeModal();
      getAllOrders(ordersSearchFilters);
    } catch (error) {
      message.error(error?.response?.data?.msg||"something went wrong");
      console.log(
        '🚀 ~ file: RejectOrderForm.jsx:23 ~ onRejectOrder ~ error',
        error,
      );
    }
  };
  return (
    <Form layout="vertical" onFinish={onRejectOrder} form={form}>
      <Form.Item
        rules={[{ required: true, message: 'Please select a target' }]}
        label="Rejection on"
        name="orderRejectedOnType"
      >
        <Select
          placeholder="Select Rejected Order party"
          options={[
            // { label: 'Customer', value: 'customer' },
            { label: 'Lezzoo', value: 'lezzoo' },
            { label: 'Merchant', value: 'merchant' },
            { label: 'Driver', value: 'driver' },
          ]}
        />
      </Form.Item>

      <RemoteSelectForm
        labelForm="Rejection Reason"
        nameForm="rejectionReason"
        placeholder="Select Rejection Reason"
        endpoint="QAOrder/rejectedReason"
        optionText="label"
        labelOption={'label'}
        valueOption={'value'}
        optionValue="value"
        labelCol={{ span: 24 }}
        dependency={[orderRejectedOnType]}
        query={{ rejection_on: orderRejectedOnType }}
        rules={[{ required: true, message: 'Please select a reason' }]}
      />

      {rejectReason == 'others' && (
        <Form.Item
          rules={[{ required: true, message: 'Please select a reason' }]}
          label="Comment"
          name="comment"
        >
          <Input placeholder=" Comment" />
        </Form.Item>
      )}

      <Button type="primary" htmlType="submit" loading={updateOrderLoading}>
        Submit
      </Button>
    </Form>
  );
};

export default RejectOrderForm;

import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Select,
  Switch,
} from 'antd';
import React, { useState } from 'react';
import { useAxiosPost, useAxiosPut } from '../../../configs/axios';

const CancelOrderForm = (props) => {
  const {
    orderCancelationData,
    selectedOrder,
    closeModal,
    getAllOrders,
    ordersSearchFilters,
  } = props;
  const [productSelectionSection, setProductSelectionSection] = useState(false);

  const {
    loading: submitOrderCancellationLoading,
    request: submitOrderCancellationRequest,
  } = useAxiosPost('/orderCancelation');

  const { request: cancelOrderstatus } = useAxiosPut('/orders/status');

  const onSubmitCancelOrder = async (valuesObjecct) => {
    try {
      // check if any field in valuesObject is undefined, if so, set it to false
      const values = Object.keys(valuesObjecct).reduce((acc, key) => {
        if (valuesObjecct[key] === undefined) {
          acc[key] = false;
        } else {
          acc[key] = valuesObjecct[key];
        }
        return acc;
      }, {});

      values.cancelationReasonId = parseInt(
        values.cancelationReasonId.split('_')[0],
      );

      const requestBody = {
        ...values,
        orderId: selectedOrder.orderId,
      };

      await submitOrderCancellationRequest(requestBody);
      await cancelOrderstatus({
        orderId: selectedOrder.orderId,
        statusId: 15,
      });
      message.success('Order Cancelation Submitted');
      closeModal();
      getAllOrders(ordersSearchFilters);
    } catch (error) {
      message.error(error?.response?.data?.msg||error.message||"something went wrong");
    }
  };
  return (
    <Form layout="vertical" onFinish={onSubmitCancelOrder}>
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
            label="Cancelation Reason"
            name="cancelationReasonId"
          >
            <Select
              showSearch
              placeholder="Select a reason"
              options={orderCancelationData?.map((item) => {
                return {
                  label: item.value,
                  value: item.id + '_' + item.value,
                };
              })}
              onChange={(value) => {
                const itemId = parseInt(value.split('_')[0]);
                const selectedReason = orderCancelationData.find(
                  (item) => item.id === itemId,
                );
                if (selectedReason.is_product_selection_needed === 1)
                  setProductSelectionSection(true);
                else setProductSelectionSection(false);
              }}
            />
          </Form.Item>

          <Form.Item label="Call made to customer" name="callMadeToCustomer">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Customer answered the call"
            name="didCustomerAnsweredTheCall"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Customer asked for cancellation"
            name="didCustomerAskedForCancelation"
          >
            <Switch />
          </Form.Item>

          <Form.Item label="Call made to merchant" name="callMadeToMerchant">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Merchant answered the call"
            name="didMerchantAnsweredTheCall"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Merchant asked for cancellation"
            name="didMerchantAskedForCancelation"
          >
            <Switch />
          </Form.Item>
          <Form.Item label="Cancelation reason " name="cancelationReasonNote">
            <Input />
          </Form.Item>
        </div>
        <div
          style={{
            width: '1px',
            backgroundColor: 'black',
            opacity: '0.1',
          }}
        ></div>
        <div
          style={{
            width: '50%',
          }}
        >
          {productSelectionSection ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '10px',
                overflowY: 'auto',
              }}
            >
              <Form.Item
                style={{
                  marginTop: '25px',
                }}
                name="unavailableItems"
              >
                <Checkbox.Group style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    {selectedOrder?.cart?.map((item, index) => {
                      return (
                        <Checkbox key={index} value={item}>
                          <div
                            name="productSelection"
                            style={{
                              backgroundColor: '#E7EFF7',
                              borderRadius: '5px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: '0 10px',
                              padding: '25px 10px',
                              width: '100%',
                            }}
                          >
                            <span>
                              <strong>{item.product_id}: </strong>
                              {item.product_name.en}
                            </span>
                          </div>
                        </Checkbox>
                      );
                    })}
                  </div>
                </Checkbox.Group>
              </Form.Item>
            </div>
          ) : (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Empty description="Cart Selection is not necessary for the selected reason" />
            </div>
          )}
        </div>
      </div>
      <Divider />
      <Button
        loading={submitOrderCancellationLoading}
        type="primary"
        danger
        htmlType="submit"
      >
        Submit Cancel Order
      </Button>
    </Form>
  );
};

export default CancelOrderForm;

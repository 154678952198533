import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useReducer,
  useContext,
} from 'react';
import { Col, Row, Typography, ConfigProvider } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import useLocalStorage from '../configs/localStorage';
import {
  axiosChatInstance,
  useAxiosTaskManagementGetV2,
} from '../configs/axios';
import ChatRoomList from '../components/Chatv2/ChatRoomList';
import { useHookstate } from '@hookstate/core';
import chatStates from '../components/Chatv2/chatStates';
import MessageList from '../components/Chatv2/MessageList';
import SocketManager from '../configs/newSocket';
import { CHAT_URL } from '../configs/constants';
import { generateFiltersData } from '../components/Chatv2/ChatFilters';
import ChatFilterDrawer from '../components/Chatv2/ChatFilterDrawer';
import ChatStatistics from '../components/Chatv2/ChatStatistics';
import ChatSidePanel from '../components/Chatv2/ChatSidePanel';
import DrawerChat from '../components/Chatv2/DrawerChat';
import TicketOnChat from '../components/TaskBoard/TicketOnChat';
import CompletedTickets from '../components/TaskBoard/CompletedTicketsModal';
import RenderTaskWithChat from '../components/TaskBoard/RenderTaskWithChat';
import ToInProgress from '../components/TaskBoard/icons/ToInProgress';

const CUSTOMER_CHATS = 'customer_agent,customer_general';
const MERCHANT_CHATS = 'merchant_agent';
const DRIVER_CHATS = 'orders,couriers';

const { Text } = Typography;

function ChatAndTicketPage() {
  const selectedAgent = useRef();
  const [auth] = useLocalStorage('auth', null);
  const chatRooms = useRef([]);
  const updateQueue = useRef([]);
  const socket = useRef(null);
  const initValue = {
    merchantChatRooms: [],
    languageChatRooms: [],
    issueTagChatRooms: [],
    agentChatRooms: [],
    unreadCount: 0,
    lastAction: 0,
    renderCount: 0,
    source: CUSTOMER_CHATS,
    selectedOrder: null,
    customer: null,
    orderRefundModalVisible: false,
    orderCartModalVisible: false,
    editOrderModalVisible: false,
    changeOrderStatusModalVisible: false,
    orderMapModalVisible: false,
    selectedMessageTab: '1',
  };

  const { theme } = useContext(ConfigProvider.ConfigContext);

  const { token } = theme;

  const backgroundColor = token.colorBgBase || '#faf9f9';

  const [state, setState] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );
  const selected_chatroom = useHookstate(
    chatStates.selectedChatRoomState.selected_chatroom,
  );

  const [activeFilters, setActiveFilters] = useState({
    roomType: null,
    roomName: null,
    roomLanguage: null,
    issueTags: null,
    roomAssignedToName: null,
  });

  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);

  const toggleFilterDrawer = () => {
    setFilterDrawerVisible(!filterDrawerVisible);
  };

  const generateFilters = useCallback(() => {
    const data = generateFiltersData(chatRooms);
    setState({
      merchantChatRooms: data.merchantRooms,
      languageChatRooms: data.languageRooms,
      issueTagChatRooms: data.issueTagRooms,
      agentChatRooms: data.agentRooms,
    });
  }, [chatRooms.current]);

  const triggerLastAction = () => {
    const timestamp = new Date().getTime();
    setState((prev) => {
      return {
        ...prev,

        lastAction: timestamp,
        renderCount: prev.renderCount + 1,
      };
    });
  };

  const fetchData = async (search = null, agentId = undefined) => {
    try {
      const response = await axiosChatInstance.get('/chats/v2/agents/rooms', {
        params: {
          chatSources: state.source,
          search: search,
          agentId,
        },
      });
      selectedAgent.current = agentId;
      if (Array.isArray(response.data)) {
        chatRooms.current = response.data;
        sortChatRooms();
        triggerLastAction();
      } else {
        console.error('Response data is not an array', response.data);
      }
    } catch (error) {
      console.error('There was an error fetching chat rooms!', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [state.source]);

  const processQueue = useCallback(() => {
    while (updateQueue.current.length > 0) {
      const update = updateQueue.current.shift();
      update();
      triggerLastAction();
    }
  }, [updateQueue.current, chatRooms.current, triggerLastAction]);

  const enqueueUpdate = useCallback(
    (updateFn) => {
      updateQueue.current.push(updateFn);
      processQueue();
    },
    [processQueue, updateQueue.current],
  );

  const updateRoomData = useCallback(
    (roomId, count, message) => {
      enqueueUpdate(() => {
        if (!Array.isArray(chatRooms.current)) {
          console.error('prevChatrooms is not an array', chatRooms.current);
          chatRooms.current = [];
          return [];
        }

        const d = chatRooms.current.map((room) => {
          if (room._id === roomId) {
            const newChat = {
              ...room,
              countMessages: count > 0 ? count : room.countMessages,
              lastMessage: message?.lastMessage || room.lastMessage,
              lastMessageTime: message?.lastMessageTime || room.lastMessageTime,
              agentLastMessageAt:
                message?.agentLastMessageAt || room.agentLastMessageAt,
              lastMessageSource:
                message?.lastMessageSource || room.lastMessageSource,
              lastMessageType: message?.lastMessageType || room.lastMessageType,
              issueTags: message?.issueTags || room.issueTags,
            };
            return newChat;
          }
          return room;
        });

        chatRooms.current = d;
        sortChatRooms();
      });
    },
    [chatRooms.current, enqueueUpdate],
  );

  const updateCounter = useCallback(
    (room) => {
      const counter = room.messageSource !== 'agent' ? room.counter : 0;
      if (
        !room.chatRoomId ||
        !room?.isForMessageSeen ||
        !state.source.includes(room.chatSource)
      )
        return;

      //console.log('updateCounter:', room.chatRoomId, counter, room);

      updateRoomData(room.chatRoomId, counter, room);
    },
    [updateRoomData],
  );
  const [statistics, setStatistics] = useState({
    total: {
      title: 'Chats',
      count: 0,
      color: 'green',
      time: 0,
    },
    unread: {
      title: 'Unread',
      count: 0,
      color: 'green',
      time: 0,
    },
    late: {
      title: 'Late',
      count: 0,
      color: 'green',
      time: 0,
    },
    help: {
      title: 'Help',
      count: 0,
      color: 'green',
      time: 0,
    },
    merchant: {
      title: 'Merchant',
      count: 0,
      color: 'green',
      time: 0,
    },
    driver: {
      title: 'Driver',
      count: 0,
      color: 'green',
      time: 0,
    },
    avgResponseTime: {
      title: 'Response',
      count: 0,
      color: 'green',
      time: 0,
    },
    avgResolutionTime: {
      title: 'Resolution',
      count: 0,
      color: 'green',
      time: 0,
    },
  });

  const generateStatistics = useCallback(() => {
    const total = chatRooms.current.length;

    const unread = chatRooms.current.filter(
      (room) => room.lastMessageSource === 'customer',
    );
    const unreadTime = //avg time to respond to unread messages
      unread.reduce((acc, room) => {
        return acc + moment().diff(room.lastMessageTime, 'minutes');
      }, 0) / unread.length;

    const late = chatRooms.current.filter(
      (room) =>
        room.lastMessageSource === 'customer' &&
        moment().diff(room.lastMessageTime, 'minutes') > 3,
    );
    const lateTime = //avg time to respond to late messages
      late.reduce((acc, room) => {
        return acc + moment().diff(room.lastMessageTime, 'minutes');
      }, 0) / late.length;

    const help = chatRooms.current.filter((room) =>
      room.roomType?.includes('customer_general'),
    ).length;
    const merchant = chatRooms.current.filter(
      (room) => room.roomType === 'merchant_agent',
    ).length;
    const driver = chatRooms.current.filter(
      (room) => room.roomType === 'orders' || room.chatSource === 'couriers',
    ).length;
    const avgResponseTime = 0;
    const avgResolutionTime = 0;

    setStatistics({
      total: {
        title: 'Chats',
        count: total,
        color: '#40425F',
        time: 0,
      },
      unread: {
        title: 'Unread',
        count: unread.length,
        color: '#e4343e',
        time: Math.round(unreadTime),
      },
      late: {
        title: 'Late',
        count: late.length,
        color: '#e4343e',
        time: Math.round(lateTime),
      },
      help: {
        title: 'Help',
        count: help,
        color: '#40425F',
        time: 0,
      },
      merchant: {
        title: 'Merchant',
        count: merchant,
        color: '#40425F',
        time: 0,
      },
      driver: {
        title: 'Driver',
        count: driver,
        color: '#40425F',
        time: 0,
      },
      avgResponseTime: {
        title: 'Response',
        count: avgResponseTime,
        color: '#40425F',
        time: 0,
      },
      avgResolutionTime: {
        title: 'Resolution',
        count: avgResolutionTime,
        color: '#40425F',
        time: 0,
      },
    });
  });

  const sortChatRooms = useCallback(() => {
    if (!Array.isArray(chatRooms.current)) {
      console.error('chatRooms is not an array', chatRooms.current);
      chatRooms.current = [];
      return;
    }

    // Sort by last message time in ascending order (oldest to newest)
    const sortedRooms = _.orderBy(
      chatRooms.current,
      [(room) => room.lastMessageTime],
      ['desc'],
    );

    const uniqueRooms = sortedRooms.filter(
      (room, index, self) =>
        self.findIndex((t) => t._id === room._id) === index,
    );

    chatRooms.current = uniqueRooms;

    // Calculate the unread count (messages with `countMessages > 0`)
    const unreadCount =
      uniqueRooms.filter((room) => room.countMessages > 0).length || 0;

    // Update state with unread count
    setState({  unreadCount});

    // Optionally, generate filters and statistics after updating the state
    // generateFilters();
    // generateStatistics();
  }, [chatRooms.current, generateFilters, generateStatistics]);

  const hiddenMessageRecepients = ['system', 'bot'];

  const onMessageReceived = (message) => {
    const {_id:userID,agentType} = auth?.user;

    const shouldUserSeeMessage =
      auth.user.isMonitorAgent || auth.user.isSupervisorAgent;

    const isRoomAssignedToUser =
      message?.chatRoom?.roomAssignedTo?.toString() === userID?.toString();
    if (!shouldUserSeeMessage && !isRoomAssignedToUser) {
      return;
    }

    if (
      selectedAgent.current &&
      selectedAgent.current !== message?.chatRoom?.roomAssignedTo.toString()
    ) {
      return;
    }
    if (agentType == 'SUPER_DS' && message?.chatRoom?.roomTeam!=='DS') {
      return;
    }
    if (agentType == 'SUPER_MS' && message?.chatRoom?.roomTeam!=='MS') {
      return;
    }
    if (agentType == 'SUPER_CA' && message?.chatRoom?.roomTeam!=='CA') {
      return;
    }
    const existingChatRoom = chatRooms.current.find(
      (room) => room._id === message.chatRoomId,
    );

    if (existingChatRoom) {
      updateRoomData(message.chatRoomId, 0, message);
    } else {
      enqueueUpdate(() => {
        if (!Array.isArray(chatRooms.current)) {
          unsole.error('chatRooms is not an array', chatRooms.current);
          chatRooms.current = [];
        }

        chatRooms.current = [message.chatRoom, ...chatRooms.current];

        const sortedRooms = _.orderBy(
          chatRooms.current,
          [(room) => room.lastMessageTime],
          ['desc'],
        );

        const uniqueRooms = sortedRooms.filter(
          (room, index, self) =>
            self.findIndex((t) => t._id === room._id) === index,
        );

        chatRooms.current = uniqueRooms;

        const unreadCount =
          uniqueRooms.filter((room) => room.countMessages > 0).length || 0;

        setState({ unreadCount, lastAction: new Date().getTime() });
      });
    }
  };

  const resolveChatRoom = useCallback(
    (chatRoom) => {
      try {
        if (!chatRoom?.roomId) {
          console.error('resolveChatRoom chatRoom is undefined');
          return;
        }
        enqueueUpdate(() => {
          if (!Array.isArray(chatRooms.current)) {
            console.error('prevChatrooms is not an array', chatRooms.current);
            chatRooms.current = [];
            return [];
          }
          const toBeResolved = chatRooms.current.find(
            (room) => room._id == chatRoom.roomId,
          );

          if (!toBeResolved) return;

          const d = _.filter(chatRooms.current, (room) => {
            return room._id != chatRoom.roomId;
          });

          chatRooms.current = [...d];
          const selectedChatRoomId =
            chatStates.selectedChatRoomState.selected_id.get();
          if (selectedChatRoomId === chatRoom.roomId) {
            chatStates.selectedChatRoomState.merge({
              selected_id: null,
              selected_chatroom: null,
            });
          }
        });
      } catch (e) {
        console.log('resolve error', e);
      }
    },
    [chatRooms.current, enqueueUpdate, chatStates.selectedChatRoomState],
  );

  const attachSocketListeners = () => {
    if (!socket.current) return;
    socket.current.on('sent-message', onMessageReceived);
    socket.current.on('chat-room-resolved', resolveChatRoom);
    socket.current.on('counter-update', updateCounter);
    socket.current.emit('join', 'agent:chatChannel');
  };

  const detachSocketListeners = () => {
    socket.current.off('sent-message', onMessageReceived);
    socket.current.off('chat-room-resolved', resolveChatRoom);
    socket.current.off('counter-update', updateCounter);
    socket.current.emit('leave', 'agent:chatChannel');
  };

  const initializeSocket = async () => {
    socket.current = new SocketManager(CHAT_URL, {
      query: {
        userId: auth?.user?._id,
        type: 'agent',
        userName: auth?.user?.displayname_en,
        userPhoto:
          'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50',
      },
      reconnection: true,
      autoConnect: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      transports: ['websocket', 'polling'],
    });

    await socket.current.connect();

    socket.current.on('connect', () => {
      console.log('Socket connected in chat v2');
      attachSocketListeners();
    });

    socket.current.on('disconnect', () => {
      console.log('Socket disconnected');
      detachSocketListeners();
    });

    socket.current.on('error', (error) => {
      console.error('Socket error', error);
      detachSocketListeners();
    });

    return socket.current;
  };

  useEffect(() => {
    initializeSocket(); // Make sure initializeSocket sets socket.current

    return () => {
      if (socket.current) {
        detachSocketListeners();
        socket.current.disconnect();
      }
    };
  }, []);

  const clearFiltersAndSearch = () => {
    setActiveFilters({
      roomType: null,
      roomName: null,
      roomLanguage: null,
      issueTags: null,
      roomAssignedToName: null,
      isModalVisible: false,
    });
  };

  const onSearch = (search, agentId) => {
    fetchData(search, agentId);
  };

  const onOpenTicketModal = () => {
    setState({ isModalVisible: true });
  };
  const setSelectedMessageTab = (value) => {
    setState({
      selectedMessageTab: value,
    });
  };
  const {
    data: dataTasks,
    request: getTasks,
    loading: getTaskLoading,
  } = useAxiosTaskManagementGetV2('/OperationTasks/v2', {
    autoRun: true,
  });

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        backgroundColor: backgroundColor,
        paddingTop: '20px',
      }}
    >
      <Row>
        <Col span={6} xs={12} xl={6}>
          <ChatRoomList
            height="calc(88vh)"
            chatSource={state.source}
            socketRef={socket.current}
            lastAction={state.lastAction}
            unreadCount={state.unreadCount}
            chatRooms={chatRooms.current}
            triggerLastAction={triggerLastAction}
            activeFilters={activeFilters}
            onSearch={onSearch}
            fetchData={fetchData}
            getTasks={getTasks}
            getTaskLoading={getTaskLoading}
            toggleFilterDrawer={toggleFilterDrawer}
            clearFiltersAndSearch={clearFiltersAndSearch}
            setSelectedMessageTab={setSelectedMessageTab}
          />
        </Col>
        <Col span={8} xs={12} xl={8}>
          <MessageList
            height="calc(88vh)"
            socketRef={socket.current}
            setSelectedMessageTab={setSelectedMessageTab}
            selectedMessageTab={state.selectedMessageTab}
          />
        </Col>

        <Col span={10} xs={12} xl={10}>
          <ShowTicketOrSideChatComponent
            selectRoom={selected_chatroom?.get()}
            onOpenTicketModal={onOpenTicketModal}
            getTasks={getTasks}
            fetchData={fetchData}
            dataTasks={dataTasks}
            getTaskLoading={getTaskLoading}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ChatAndTicketPage;
const ShowTicketOrSideChatComponent = ({
  selectRoom = {},
  onOpenTicketModal,
  getTasks,
  dataTasks,
  getTaskLoading,
}) => {
  const { roomType, roomRef, _id } = selectRoom || {};
  if (!_id && !roomRef)
    return (
      <TicketOnChat
        onOpenTicketModal={onOpenTicketModal}
        getTasks={getTasks}
        dataTasks={dataTasks}
        getTaskLoading={getTaskLoading}
      />
    );
  // return <span>sad</span>
  return (
    <div
      style={{
        height: '90vh',
        overflow: 'scroll',
      }}
    >
      <ChatSidePanel chatRoom={selectRoom} />
    </div>
  );
};

import React, { memo, useContext, useRef, useState } from 'react';
import {
  Avatar,
  Typography,
  Badge,
  Tag,
  Row,
  Col,
  List,
  ConfigProvider,
  Tooltip,
} from 'antd';
import {
  ClockCircleOutlined,
  UserOutlined,
  TeamOutlined,
  MessageOutlined,
  TagOutlined,
  CustomerServiceOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  chatRoomItemStyle,
  chatRoomItemSelectedStyle,
  darkChatRoomItemStyle,
  darkChatRoomItemSelectedStyle,
  lightColors,
  darkColors,
} from './ChatRoomListItemStyles';
import _ from 'lodash';
const { Text } = Typography;

const getTime = (time) => {
  const timeDiff = moment().diff(moment(time), 'seconds');
  // now, 10sec, 1min, 1hr, 1day, 1week, 1month, 1year
  //short text
  const timeDiffText =
    timeDiff < 10
      ? 'now'
      : timeDiff < 60
      ? `${timeDiff}s`
      : timeDiff < 3600
      ? `${Math.floor(timeDiff / 60)}m`
      : timeDiff < 86400
      ? `${Math.floor(timeDiff / 3600)}h`
      : timeDiff < 604800
      ? `${Math.floor(timeDiff / 86400)}d`
      : timeDiff < 2592000
      ? `${Math.floor(timeDiff / 604800)}w`
      : timeDiff < 31536000
      ? `${Math.floor(timeDiff / 2592000)}mo`
      : `${Math.floor(timeDiff / 31536000)}y`;
  return timeDiffText;
};
const getchatId = (roomType, refId) => {
  const color = roomType === 'customer_general' ? '#DE9494' : '#16325B';
  const text = roomType === 'customer_general' ? 'Help' : refId;
  return {
    color: color,
    text: text,
  };
};

const getTags = (
  roomType,
  roomAssignedToName,
  roomLanguage,
  issueTags,
  refId,
) => {
  const tags = [];
  if (issueTags && issueTags?.length > 0) {
    const length = issueTags?.length || 0;
    //return max 3, the rest +length
    issueTags.slice(0, 3).forEach((tag) => {
      tags.push({
        color: tag.color,
        text: tag.name,
      });
    });
  }
  return tags;
};

const getRecepient = (recepient) => {
  if (recepient === 'customer') {
    return {
      color: '#40425F',
      text: 'C',
    };
  }
  if (recepient === 'agent') {
    return {
      color: '#868AAD',
      text: 'A',
    };
  } else {
    return {
      color: 'black',
      text: 'N/A',
    };
  }
};

const getLanguageColor = (language) => {
  switch (language) {
    case 'kr':
      return { bg: '#FDD7D5', txt: '#D17070' };
    case 'bd':
      return { bg: '#FDF4D5', txt: '#B1A748' };
    case 'ar':
      return { bg: '#EED5FD', txt: '#9A70D1' };
    default:
      return { bg: '#D5DDFD', txt: '#7091D1' };
  }
};

const getLanguageName = (language) => {
  switch (language) {
    case 'kr':
      return 'Sorani';
    case 'bd':
      return 'Badini';
    case 'ar':
      return 'Arabic';
    default:
      return 'English';
  }
};

const ChatRoomListItem = memo(
  ({ item, onSelect, isSelected }) => {
    //a pure component that renders a chat room list item with row and column layout of Ant Design components
    // the component receives everything as props to not have any logic inside the component

    const renderCount = useRef(0); // Use useRef for persistent count

    const { theme } = useContext(ConfigProvider.ConfigContext);

    const isDarkMode = theme.algorithm === 'dark';

    const RenderChatRoom = ({
      avatar,
      title,
      subtitle,
      time,
      agentTime,
      count,
      message,
      refId,
      type,
      onSelect,
      isSelected,
      roomType,
      roomAssignedToName,
      roomLanguage,
      issueTags,
      recepient,
    }) => {
      const timeDiffText = getTime(time);
      const timeAgentDiffText = agentTime ? getTime(agentTime) : undefined;
      const tags = getTags(
        roomType,
        roomAssignedToName,
        roomLanguage,
        issueTags,
        refId,
      );

      // const unReadChatCount = [1,3,2]?.filter((chat) =>
      //   selectedAgent
      //     ? chat.roomAssignedTo === selectedAgent?.key && chat.countMessages > 0
      //     : chat?.countMessages > 0,
      // );

      const roomId = getchatId(roomType, refId);

      // Get the timeDiff from getTime function
      const timeDiff = moment().diff(moment(time), 'seconds'); // Calculate timeDiff here

      const timeTagStyle = {
        backgroundColor: timeDiff > 300 ? '#FF6F61' : '#A5D6A7', // Soft red for 5+ minutes, soft green for less than 5 minutes
        color: timeDiff > 300 ? '#FFFFFF' : '#1B5E20',
        borderRadius: '20px', 
        padding: '3px 8px', 
        fontSize: '13px',
        fontWeight: '600', 
        border: '1px solid' + (timeDiff > 300 ? '#FF6F61' : '#A5D6A7'),
      };
      

      const light = {
        container: chatRoomItemStyle,
        selectedContainer: chatRoomItemSelectedStyle,
        colors: lightColors,
      };

      const dark = {
        container: darkChatRoomItemStyle,
        selectedContainer: darkChatRoomItemSelectedStyle,
        colors: darkColors,
      };

      const style = isDarkMode ? dark : light;

      renderCount.current++; // Increment the count on each render
      return (
        <>
          <span
            style={{
              paddingRight: '30px',
              paddingLeft: '30px',
              marginBottom: '10px',
            }}
            // onClick={() => onChatSourceTabClick(CUSTOMER_TAB)}
          >
            {/* <Badge
              offset={[25, 0]}
              count={`${unReadChatCount?.length || 0} / ${
                allChats?.length || 0
              }`}
            >
              Customers
            </Badge> */}
          </span>
          <div
            style={isSelected ? style.selectedContainer : style.container}
            onClick={() => onSelect()}
          >
            <div
              style={{
                position: 'absolute',
                top: '-10px',
                left: 15,
                zIndex: 9,
              }}
            >
              <Tag
                color={roomId.color}
                icon={
                  roomType === 'customer_general' ? (
                    <CustomerServiceOutlined />
                  ) : (
                    <TagOutlined />
                  )
                }
              >
                {' '}
                {roomId.text}
              </Tag>

              <Tag
                color="#e9ecef"
                style={{
                  color: 'black',
                }}
                icon={<TeamOutlined />}
              >
                {' '}
                {roomAssignedToName}
              </Tag>
              {!agentTime ? <Tag color="red">Not Answered</Tag> : null}
            </div>
            {count > 0 ? (
              <div
                style={{
                  position: 'absolute',
                  top: '1px',
                  right: '1px',
                  color: 'white',
                }}
              >
                <Badge
                  color="black"
                  style={{
                    borderRadius: '20%',
                  }}
                  count={count}
                />
              </div>
            ) : null}
            <Row
              style={{
                marginBottom: '5px',
                borderBottom: '1px solid ' + style.colors.separator,
                fontFamily: 'Roboto, sans-serif', // Example of a modern font
              }}
            >
              <Col
                span={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '20px',
                }}
              >
                <Avatar
                  src={avatar}
                  style={{ border: '1px solid #d9d9d9', borderRadius: '15px' }} // Changes to avatar
                  size={40}
                />
              </Col>
              <Col>
                <div
                  style={{
                    marginLeft: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '8px', // Adds consistent vertical spacing between elements
                  }}
                >
                  {/* Title and Time */}
                  <Text
                    style={{
                      color: style.colors.title,
                      fontSize: '16px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center', // Align text and tag horizontally
                      gap: '10px', // Space between title and tag
                    }}
                  >
                    {title}
                    <Tag style={timeTagStyle}>{timeDiffText}</Tag>
                  </Text>

                  {/* Subtitle and Language Tag */}
                  <Text
                    type="secondary"
                    style={{
                      fontSize: '14px',
                      color: style.colors.content,
                      display: 'flex',
                      alignItems: 'center',
                      // gap: '5px', // Space between text and tag
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '14px',
                        color: style.colors.content,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        marginRight: '20px',
                      }}
                    >
                      {type === 'customer_general' ? (
                        <Tag
                          style={{
                            backgroundColor: '#ffcc80',
                            color: '#d32f2f',
                            fontSize: '13px',
                            fontWeight: '600',
                            borderRadius: '12px',
                            padding: '4px 10px',
                          }}
                        >
                          C_ID: {refId}
                        </Tag>
                      ) : (
                        <Text style={{ color: style.colors.content }}>
                          {subtitle}
                        </Text>
                      )}
                    </Text>

                    {roomLanguage && (
                      <Tag
                        style={{
                          color: getLanguageColor(roomLanguage).txt,
                          fontWeight: '300', // Slightly bold for better visibility
                        }}
                        color={getLanguageColor(roomLanguage).bg}
                      >
                        {getLanguageName(roomLanguage)}
                      </Tag>
                    )}
                  </Text>
                </div>
              </Col>
              {/* <Col xs={12} xl={3} span={2}>
              <div>
                <Text
                  style={{
                    color: style.colors.content,
                  }}
                >
                  <Tooltip title="Last agent message time">
                    {timeAgentDiffText}
                  </Tooltip>
                </Text>
              </div>
            </Col> */}
            </Row>
            <Row
              style={{
                borderBottom: '1px solid ' + style.colors.separator,
                paddingBottom: '5px',
                paddingLeft: '55px',
              }}
              span={24}
            >
              {tags?.slice(0, 3).map((tag, index) => (
                <Tag key={index} color={tag.color}>
                  {tag.text}
                </Tag>
              ))}
              {tags?.length > 3 ? (
                <Tag color="blue">{tags.length - 3}</Tag>
              ) : null}
            </Row>
            <Row style={{ paddingRight: '5px', paddingLeft: '50px' }}>
              <Text
                style={{
                  flex: 35,
                  color:
                    count > 0
                      ? getRecepient(recepient).color
                      : style.colors.content,
                  fontWeight:
                    count > 0 && recepient === 'customer' ? 'bold' : 'normal',
                }}
                numberOfLines={1}
                ellipsis={true}
              >
                {/* <MessageOutlined
              style={{
                marginRight: '8px',
                paddingLeft: '35px',
                fontSize: '16px', // size of the icon
                }}
              />{' '} */}
                {message}
              </Text>
            </Row>
          </div>
        </>
      );

      //style
    };

    return (
      <RenderChatRoom
        key={item?._id}
        avatar={item.chatRoomImage}
        title={item.roomName}
        subtitle={item.roomSubtitle}
        time={item.lastMessageTime}
        agentTime={item.agentLastMessageAt}
        count={item.countMessages}
        message={item.lastMessage}
        refId={item.roomRef}
        type={item.roomType}
        onSelect={onSelect}
        isSelected={isSelected}
        roomType={item.roomType}
        roomAssignedToName={item.roomAssignedToName}
        roomLanguage={item.roomLanguage}
        issueTags={item.issueTags}
        recepient={item.lastMessageSource}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps?.isSelected === nextProps?.isSelected &&
      _.isEqual(prevProps?.item, nextProps?.item)
    );
  },
);

export default ChatRoomListItem;
